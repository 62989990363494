import { OptionItems } from 'app/models';
import { FieldHookConfig, useField } from 'formik';
import _ from 'lodash';
import React from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';

interface OtherProps {
  label: string;
  horizontal?: boolean;
  placeholder?: string;
  options: OptionItems[];
  onChange?: (value?: OptionItems) => void;
  isClearable?: boolean;
  disabled?: boolean;
}

const SelectField = (props: FieldHookConfig<string> & OtherProps) => {
  const [field, meta, helpers] = useField(props);

  const { label, placeholder, options, onChange, isClearable, disabled } =
    props;
  const rendervalue = () => {
    const index = _.findIndex(options, option => option.value === field.value);
    if (index > -1) {
      return options[index];
    }
    return null;
  };

  return (
    <div
      className={`mb-2 form-group ${
        meta.touched && meta.error ? 'text-danger' : ''
      }`}
      style={{ height: 85 }}
    >
      {label ? <Label>{props.label}</Label> : null}
      <Select
        // value={selectedMulti}
        defaultValue={rendervalue()}
        isMulti={false}
        placeholder={placeholder}
        options={options}
        // formatOptionLabel={option => option.label}
        classNamePrefix="select2-selection"
        {...field}
        value={rendervalue()}
        onChange={e => {
          if (e) {
            helpers.setValue(e.value);
          } else {
            helpers.setValue('');
          }
          if (onChange) {
            onChange(e);
          }
        }}
        isClearable={isClearable}
        isDisabled={disabled}
      />
      {meta.touched && meta.error ? (
        <div className="text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default SelectField;
