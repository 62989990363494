/**
 *
 * Asynchronously loads the component for SurveyResponseDetailsPage
 *
 */
import { lazyLoad } from 'utils/loadable';

export const SurveyResponseDetailsPage = lazyLoad(
  () => import('./index'),
  module => module.SurveyResponseDetailsPage,
);
