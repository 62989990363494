import { API_URL } from 'app/config';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const instance = axios.create({
  baseURL: API_URL + '/v1/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  timeout: 600000,
});

const AxiosInterceptor = ({ children }: { children: ReactElement }) => {
  const history = useHistory();

  useEffect(() => {
    const resInterceptor = (response: AxiosResponse): AxiosResponse => {
      return response;
    };

    const errInterceptor = (error: AxiosError): Promise<AxiosError> => {
      if (error?.response?.status === 401) {
        history.push('/logout');
      } else if (error?.response?.status === 403) {
        history.push('/logout');
      }
      return Promise.reject(error);
    };

    const interceptor = instance.interceptors.response.use(
      resInterceptor,
      errInterceptor,
    );

    return () => instance.interceptors.response.eject(interceptor);
  }, [history]);

  return children;
};

export default instance;
export { AxiosInterceptor };
