export const BANNER_TYPE_OPTIONS = [
  { label: '活動', value: 'event' },
  { label: '公司活動', value: 'companyActivity' },
  { label: '課程', value: 'course' },
  { label: '新聞', value: 'news' },
  { label: '資源及評價', value: 'survey' },
  // { label: '會員Tag權限', value: 'userTag' },
];

export const BANNER_STATUS = [
  { label: '已發佈', value: 'Published' },
  { label: '未發佈', value: 'Unpublished' },
];

export const BANNER_ALL_STATUS_OPTIONS = [
  { label: '所有', value: '' },
  { label: '已發佈', value: 'Published' },
  { label: '未發佈', value: 'Unpublished' },
];
