import { Filter, ListResult } from 'app/models';
import { NewsCategoryData, NewsData } from 'app/models/News';

import Axios from './';

/**
* * Get news list (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/news)
+*/

export interface GetNewsListParams extends Filter<NewsData> {
  category: string;
  title: string;
}

export const getNewsList = async (params: GetNewsListParams) => {
  const { data } = await Axios.get<ListResult<NewsData>>('/news', { params });
  return data;
};

/**
* * Get one news (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/news/-newsId-)
+*/

export const getOneNews = async (newId: string | number) => {
  const { data } = await Axios.get<NewsData>(`/news/${newId}`);
  return data;
};

/**
* * get news category list (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/news/category)
+*/

export interface GetNewsCategoryListParams extends Filter<NewsCategoryData> {}

export const getNewsCategoryList = async () => {
  const { data } = await Axios.get<NewsCategoryData[]>('/news/category');
  return data;
};

/**
* * create one news (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/news)
+*/

export interface CreateOneNewsParams {
  categoryName: string;
  title: string;
  surveyId: number | null;
  content: string | null;
  bannerImage: string;
  publishedDate: string;
  permissionTags: string[];
}

export const createOneNews = async (params: CreateOneNewsParams) => {
  const { data } = await Axios.post<NewsData>('/news', params);
  return data;
};

/**
* * update one news by newsId (https://svgroup-app-dev.appicidea.com/doc/v2#patch-/v1/news/-newsId-)
+*/

export interface UpdateOneNewsParams {}

export const updateOneNews = async (
  newsId: number | string,
  params: UpdateOneNewsParams,
) => {
  const { data } = await Axios.patch<NewsData>(`/news/${newsId}`, params);
  return data;
};

/**
* * update all category order(https://svgroup-app-dev.appicidea.com/doc/v2#put-/v1/news/category/ordering)
+*/

export interface PutNewsCategoryParams {}

export const updateNewsCategoryList = async (params: PutNewsCategoryParams) => {
  const { data } = await Axios.put<NewsCategoryData>(
    `/news/category/ordering`,
    params,
  );
  return data;
};

/**
* * delete one news by newsId (https://svgroup-app-dev.appicidea.com/doc/v2#patch-/v1/news/-newsId-)
+*/

export const deleteOneNews = async (newsId: number | string) => {
  const { data } = await Axios.delete<string>(`/news/${newsId}`);
  return data;
};
