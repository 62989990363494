/**
 *
 * UserTagList
 */
import Breadcrumbs from 'app/components/Common/Breadcrumb';
import TableCount from 'app/components/Common/TableCount';
import { SearchBar } from 'app/components/Filter';
import { Dialog } from 'app/components/Modal';
import { Pagination, Table } from 'app/components/Table';
import { UploadUserTagTemplateForm } from 'app/components/User';
import { API_URL, APP_NAME } from 'app/config';
import { useTableData } from 'app/hooks';
import { UserTagItem } from 'app/models';
// service
import { GetUserTagListParams, getUserTagList } from 'app/services/UserService';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import UserTagListColumns from './UserTagListColumns';

export const UserTagListPage = () => {
  const [isVisible, setVisible] = useState<boolean>(false);

  const {
    dataList: userTagList,
    count,
    page,
  } = useTableData<GetUserTagListParams, UserTagItem>({
    call: getUserTagList,
    filterVariableArray: ['asc', 'desc', 'tagName'],
  });

  return (
    <div className="page-content">
      <Helmet>
        <title>會員Tags列表 | {APP_NAME}</title>
      </Helmet>
      <Container fluid>
        <Breadcrumbs title="會員Tags" breadcrumbItem="會員Tags列表" />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <>
                  <Row className="mb-2">
                    <Col sm={12} className="d-flex justify-content-end">
                      <button
                        className="btn btn-info me-3"
                        onClick={() => setVisible(true)}
                      >
                        <i className="mdi mdi-upload" />
                        {` ${'上載會員列表'}`}
                      </button>
                      <a
                        href={`${API_URL}/v1/users/tags/template`}
                        className="btn btn-success me-3"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="mdi mdi-download" />
                        {` ${'下載會員範本'}`}
                      </a>
                      <div className="d-flex flex-row-reverse">
                        <a
                          href="/user-tags/new"
                          className="btn btn-primary"
                          role="button"
                          aria-pressed="true"
                        >
                          <i className="mdi mdi-plus" />
                          {` ${'新增Tag'}`}
                        </a>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col lg={4}>
                      <SearchBar searchField="tagName" label="Tag" />
                    </Col>
                    <Col
                      sm={8}
                      className="d-flex align-self-end justify-content-end"
                    >
                      <TableCount count={count} title="Tag數目" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <Table
                          columns={UserTagListColumns()}
                          keyField="id"
                          data={userTagList || []}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-3">
                    <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                      <Pagination count={count} current={page} />
                    </Col>
                  </Row>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Dialog
        visible={isVisible}
        onClose={() => setVisible(false)}
        title="上載會員列表"
        size="lg"
      >
        <UploadUserTagTemplateForm setDialogVisible={setVisible} />
      </Dialog>
    </div>
  );
};
