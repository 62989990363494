export const STATEMENT_ISSETTLED_OPTIONS = [
  { label: '所有', value: '' },
  { label: '已繳付', value: 'settled' },
  { label: '待確定', value: 'hidden' },
  { label: '已逾期', value: 'expired' },
  { label: '等待繳款', value: 'pending' },
];

export const STATEMENT_CREATE_OPTIONS = [
  { label: '待確定', value: 'hidden' },
  { label: '等待繳款', value: 'pending' },
];
