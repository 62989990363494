import { Filter, ListResult, PreAgentData } from 'app/models';

import Axios from '.';

/**
* * get All User Preagent List (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/banners)
+*/

export interface GetAllUserPreagentListParams extends Filter<PreAgentData> {
  userId?: number;
  studentNumber?: string;
}

export const getAllUserPreagentList = async (
  params: GetAllUserPreagentListParams,
) => {
  const { data } = await Axios.get<ListResult<PreAgentData>>('/preAgents', {
    params,
  });
  return data;
};
