import { Admin } from './Admin';
import { TimeStamp } from './Common';
import { CourseApplicationData } from './Course';
import { EventApplicationData } from './Event';
import { StatementData } from './Statement';

export type UserType = '公眾' | '會員';
export type UserStatus = '已停用' | '已啟用';
export type MarriageStatus = '已婚' | '未婚';
export type MemberLevel = '紅寶石' | '黃鑽' | '鑽石' | '綠寶石' | '藍寶石';

export interface User extends TimeStamp {
  firstName: string;
  lastName: string;
  englishName: string | null;
  avatar: null | string;
  email: string;
  chineseName: string;
  displayName?: null | string;
  uid: string;
  userId: number;
  isReceivedInformation: boolean;
  notificationToken: string;
  onceId: string;
  qrcode: string;
  status: UserStatus;
  membershipPoint: number;
  agentLicenseNumber: string | null;
  userType: UserType;
  background: string;
  cometChatId: string;
  marriageStatus: MarriageStatus | null;
  memberLevel: MemberLevel | null;
  jobTitle: string | null;
  contactPhone: string | null;
  quote: string;
  UserAuths: UserAuth[];
  isStaff: boolean;
  rankCode: string | null;
  UserTags: UserTag[];
  branchCode: string;
  branchName: string;
  firstLoginTime: string | null;
  family: string | null;
  cardAddress: string | null;
  entryDate: string | null;
  birthDate: string | null;
}

export interface UserCommonAuth extends TimeStamp {
  userAuthId: number;
  userId: number;
  provider: string;
  loginName: string | null;
  email: string | null;
  uid: string | null;
  phoneNumber: number | null;
  hash: string | null;
}

export interface Auths extends UserCommonAuth {}
export interface UserAuth extends UserCommonAuth {}

export interface UserTagItem {
  tagName: string;
  userCount: number;
}

export interface UserTag extends TimeStamp, UserTagItem {
  userId: number;
  userTagId: number;
}

export enum RankCodeOptions {
  'SERD' = '高級行政區域總監',
  'ERD' = '行政區域總監',
  'SRD' = '高級區域總監',
  'RD' = '區域總監',
  'SDD' = '高級分區總監',
  'DD' = '分區總監',
  'SBM' = '高級分行經理',
  'BM' = '分行經理',
  'SUM' = '高級分組經理',
  'UM' = '分組經理',
}

export type RankCodeType = keyof typeof RankCodeOptions;

export interface UserMemberPointRecordData extends TimeStamp {
  membershipPointId: number;
  point: number;
  userId: number;
  eventApplicationId: number | null;
  courseApplicationId: number | null;
  statementId: number | null;
  adminId: number | null;
  remarks: string | null;
  EventApplication: EventApplicationData;
  CourseApplication: CourseApplicationData;
  Statement: StatementData;
  Admin: Admin;
}
