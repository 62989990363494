import { AdminRoleInfo, AdminRoleType, OptionItems } from 'app/models';

export const ADMIN_ROLES = new Map<AdminRoleType, AdminRoleInfo>([
  ['admin', { label: '超級管理員', value: 'admin', color: 'warning' }],
  ['staff', { label: '職員', value: 'staff', color: 'success' }],
  ['company', { label: '公司或機構管理員', value: 'staff', color: 'primary' }],
]);

export const ADMIN_ROLES_OPTIONS: OptionItems[] = [
  {
    label: '超級管理員',
    value: 'admin',
  },
  {
    label: '職員',
    value: 'staff',
  },

  {
    label: '公司或機構管理員',
    value: 'company',
  },
];
