import {
  Filter,
  ListResult,
  MemberLevel,
  User,
  UserMemberPointRecordData,
  UserTagItem,
  UserType,
} from '../models';
import Axios from './';

/**
* * get all Users (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/users)
+*/

export interface GetUserListParams extends Filter<User> {
  displayName?: string;
  name?: string;
  agentLicenseNumber?: string;
  email?: string;
  memberLevel?: MemberLevel;
  userType?: UserType;
  jobTitle?: string;
  rankCode?: string[];
  tags?: string[];
  branchCodes?: string[];
}

export const getUserList = async (params: GetUserListParams) => {
  const { data } = await Axios.get<ListResult<User>>('/users', { params });
  return data;
};

/**
* * get all membership point record (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/membership/point)
+*/
export interface GetUserMembershipPointListParams
  extends Filter<UserMemberPointRecordData> {
  userId?: number;
  courseId?: number;
  eventId?: number;
}

export const getUserMembershipPoint = async (
  params: GetUserMembershipPointListParams,
) => {
  const { data } = await Axios.get<ListResult<UserMemberPointRecordData>>(
    '/membership/point',
    { params },
  );
  return data;
};

/**
* * get one User (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/users/-userId-)
+*/

export const getOneUser = async (userId: number | string) => {
  const { data } = await Axios.get<User>(`/users/${userId}`);
  return data;
};

/**
* * Create one User (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/users)
+*/

export interface createOneUserParams {
  email: string;
  displayName: string | null;
  isReceivedInformation: boolean;
  avatar: any | null;
  agentLicenseNumber: string | null;
  contactPhone: string | null;
  userType: UserType;
  memberLevel: string | null;
  jobTitle: string | null;
  quote: string;
  password?: string;
  rankCode: string | null;
  isStaff: boolean;
  branchCode: string | null;
  branchName: string | null;
}

export const createOneUser = async (params: createOneUserParams) => {
  const { data } = await Axios.post<User>(`/users`, params);
  return data;
};

/**
* * update one User (https://svgroup-app-dev.appicidea.com/doc/v2#patch-/v1/users/-userId-)
+*/

export interface UpdateOneUserParams {
  avatar: string;
  email: string | null;
  displayName: string | null;
  background: string;
  membershipPoint: number;
}

export const updateOneUser = async (
  userId: number | string,
  params: Partial<UpdateOneUserParams>,
) => {
  const { data } = await Axios.patch<null>(`/users/${userId}`, params);
  return data;
};

/**
* * update one User (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/membership/point)
+*/

export interface UpdateOneUserMembershipPointParams {
  userId: number | string;
  point: number;
  adminId?: number | string;
  remarks?: string;
}

export const updateOneUserMembershipPoint = async (
  params: UpdateOneUserMembershipPointParams,
) => {
  const { data } = await Axios.post<any>(`/membership/point`, params);
  return data;
};

/**
* * Activate one User (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/membership/point)
+*/

export const activateOneUser = async (userId: number | string) => {
  const { data } = await Axios.post<any>(`/users/${userId}/activate`);
  return data;
};

/**
* * Deactivate one User (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/membership/point)
+*/

export const deactivateOneUser = async (userId: number | string) => {
  const { data } = await Axios.post<any>(`/users/${userId}/deactivate`);
  return data;
};

/**
* * Get User Tag List (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/users/tags
+*/

export interface GetUserTagListParams extends Filter<UserTagItem> {
  tagName?: string;
}

export const getUserTagList = async (params: GetUserTagListParams) => {
  const { data } = await Axios.get<ListResult<UserTagItem>>(`/users/tags`, {
    params,
  });
  return data;
};

/**
* * create one User tag (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/users/tags)
+*/

export const createOneUserTag = async (
  tagName: string,
  userIds: number[] | string[],
) => {
  const { data } = await Axios.post<any>(`/users/tags`, {
    tagName,
    userIds,
  });
  return data;
};

/**
* * update one User tag (https://svgroup-app-dev.appicidea.com/doc/v2#patch-/v1/users/tags/-tagName-)
+*/

export const updateOneUserTag = async (original: string, tagName) => {
  const { data } = await Axios.patch<any>(`/users/tags/${original}`, {
    tagName,
  });
  return data;
};

/**
* * delete one User tag (https://svgroup-app-dev.appicidea.com/doc/v2#delete-/v1/users/tags/-tagName-)
+*/

export const deleteOneUserTag = async (tagName: string) => {
  const { data } = await Axios.delete<any>(`/users/tags/${tagName}`);
  return data;
};

/**
* * get users by tag name (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/users/tags)
+*/

export interface GetUserListByTagNameParams extends Filter<User> {}

export const getUserListByTagName = async (
  tagName: string,
  params: GetUserListByTagNameParams,
) => {
  const { data } = await Axios.get<ListResult<User>>(
    `/users/tags/${tagName}/users`,
    {
      params,
    },
  );
  return data;
};

/**
* * delete one preagent (https://svgroup-app-dev.appicidea.com/doc/v2#delete-/v1/preAgents/-preAgentId-)
+*/

export const deleteOnePreagent = async (preAgentId: string | number) => {
  const { data } = await Axios.delete<any>(`/preAgents/${preAgentId}`);
  return data;
};

/**
* *  Import Or Update User From Excel File (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/users/import)
+*/

export const importUserExcel = async (fd: FormData) => {
  const { data } = await Axios.post<any>(`/users/import`, fd);
  return data;
};

/**
* *  Import User Tag From Excel File (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/users/tags/import)
+*/

export const importUserToOneTags = async (fd: FormData) => {
  const { data } = await Axios.post<any>(`/users/tags/import`, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
};

/**
* *  Get Access Token of one User, Admin Only (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/users/-userId-/auths)
+*/

export const getAccessToken = async (userId: number | string) => {
  const { data } = await Axios.get<string>(`/users/${userId}/accessToken`);
  return data;
};
