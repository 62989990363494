/**
 *
 * Asynchronously loads the component for EventListPage
 *
 */
import { lazyLoad } from 'utils/loadable';

export const EventListPage = lazyLoad(
  () => import('./index'),
  module => module.EventListPage,
);
