import { OptionItems } from 'app/models';

export const USER_STATUS_OPTION: OptionItems[] = [
  { value: '已啟用', label: '已啟用' },
  { value: '已停用', label: '已停用' },
];

export const USER_RECEIVED_INFO: OptionItems[] = [
  { value: 'true', label: '接受' },
  { value: 'false', label: '不接受' },
];

export const USER_MARRIAGE_STATUS: OptionItems[] = [
  { value: '已婚', label: '已婚' },
  { value: '未婚', label: '未婚' },
];

export const USER_ALL_TYPE: OptionItems[] = [
  { value: '', label: '所有' },
  { value: '公眾', label: '公眾' },
  { value: '會員', label: '會員' },
];

export const USER_TYPE: OptionItems[] = [
  { value: '公眾', label: '公眾' },
  { value: '會員', label: '會員' },
];

export const USER_MEMBER_LEVEL: OptionItems[] = [
  { value: '黃鑽', label: '黃鑽' },
  { value: '鑽石', label: '鑽石' },
  { value: '綠寶石', label: '綠寶石' },
  { value: '紅寶石', label: '紅寶石' },
  { value: '藍寶石', label: '藍寶石' },
  { value: '純銀', label: '純銀' },
  { value: '普通會員', label: '普通會員' },
  { value: '', label: '沒有等級' },
];
