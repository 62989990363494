import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Admin } from 'app/models';
import { adminLogout } from 'app/services/AdminServices';
import { toast } from 'react-toastify';
import { AppThunk } from 'store';

interface AuthState {
  error: string | null;
  success: string | null;
  loginLoading: boolean;
  loginError: string;
  Auth: Admin | null;
}

const initialState = {
  error: '',
  success: 'ok',
  loginLoading: false,
  loginError: '',
  Auth: null,
} as AuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart(state) {
      state.loginLoading = true;
      state.loginError = '';
    },
    loginSuccess(state) {
      state.loginLoading = false;
      state.loginError = '';
    },
    loginFail(state, action: PayloadAction<string>) {
      state.loginLoading = false;
      state.loginError = action.payload;
    },
    setProfile(state, action: PayloadAction<Admin | null>) {
      state.Auth = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const { loginStart, loginSuccess, loginFail, setProfile } =
  authSlice.actions;
export default authSlice.reducer;

// dispatch actions

// export const loginAdmin = (
//   params: AdminAuthRequest,
//   history,
// ): AppThunk => async dispatch => {
//   dispatch(loginStart());
//   try {
//     const res = await api.v1.authControllerAdminLogin(params);
//     dispatch(setProfile(res));
//     dispatch(loginSuccess());
//     history.push('/');
//   } catch (error) {
//     console.log(error);
//     dispatch(loginFail(''));
//   }
// };

// export const logoutAdmin = (history): AppThunk => async dispatch => {
//   try {
//     await api.v1.authControllerLogout();
//     dispatch(setProfile(null));
//     history.replace('/login');
//   } catch (err) {
//     toast.warning('請檢查你的網絡。');
//   }
// };

export const logoutAdmin =
  (history): AppThunk =>
  async dispatch => {
    try {
      await adminLogout();
      dispatch(setProfile(null));
      localStorage.clear();
      history.replace('/login');
    } catch (err) {
      console.error(err);
      toast.warning('請檢查你的網絡。');
    }
  };

// export const getCurrentProfile = (): AppThunk => async dispatch => {
//   try {
//     console.log('get admin profile');
//     const profile = await getCurrentAdminProfile();
//     dispatch(setProfile(profile));
//   } catch (err: any) {
//     console.error(err.response);
//     if (!err.response || err.response.status !== 401) {
//       toast.warning('請檢查你的網絡。');
//     }
//   }
// };
