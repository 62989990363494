import { PreAgentData } from 'app/models';

const SelectPreagentListColumns = () => [
  {
    text: '姓名',
    dataField: '',
    formatter: (preagent: PreAgentData) => (
      <>
        <div
          className="font-size-14"
          style={{
            width: '150px',
            maxWidth: '150px',
            whiteSpace: 'break-spaces',
            margin: 0,
          }}
        >
          <h5 className="text-dark" style={{ width: '200px' }}>
            {`${preagent.lastName} ${preagent.firstName}`}
          </h5>
        </div>
      </>
    ),
  },
  {
    text: '申請人',
    dataField: '',
    formatter: (preagent: PreAgentData) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {preagent?.User?.displayName ? preagent.User.displayName : '-'}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '學生號碼',
    dataField: 'studentNumber',
    canSort: true,
    formatter: (preagent: PreAgentData) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">{preagent.studentNumber}</div>
        </h5>
      </>
    ),
  },
  {
    text: '身分證',
    dataField: 'hkId',
    canSort: true,
    formatter: (preagent: PreAgentData) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">{preagent.hkId}</div>
        </h5>
      </>
    ),
  },
  {
    text: '電郵',
    dataField: 'email',
    canSort: true,
    formatter: (preagent: PreAgentData) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">{preagent.email}</div>
        </h5>
      </>
    ),
  },
];

export default SelectPreagentListColumns;
