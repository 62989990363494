import { toast } from 'react-toastify';

export const CustomImportErrorMEssageHelper = (errMsg: string) => {
  switch (errMsg) {
    case 'User not found':
      return '找不到會員';
    case 'Missing agent Code':
      return '找不到會員';
    case 'Wrong agent Code':
      return '找不到會員';
    case "Incorrect DATETIME value: 'Invalid date'":
      return '日期錯誤';
    case 'Either student Number or agent Code':
      return '只能在同一行輸入會員編號或準成員學生編號';
    case 'Attendee already applied for this course':
      return '重複的準成員學生編號';
    case 'Wrong student number':
      return '準成員學生號碼錯誤';
    default:
      return `資料錯誤 ${errMsg ? '(' + errMsg + ')' : ''}`;
  }
};

// export const
export const returnImportErrorToastHelper = res => {
  return toast.error(
    `${CustomImportErrorMEssageHelper(res.errors[0].message)}
   ，請重試(第${res.errors[0]?.index}行)
    `,
    {
      autoClose: false,
    },
  );
};

export const returnImprtIgnoreToastHelper = res => {
  return toast.error(
    `${CustomImportErrorMEssageHelper(res.ignored[0].message)}，請重試 (第${
      res.ignored[0]?.index
    }行)`,
    { autoClose: false },
  );
};

export const returnUpdatedErrorToastHelper = (
  loadingRes: string | number,
  res,
) => {
  if (res?.insertedRows) {
    toast.success(`已新增${res?.insertedRows}筆資料`, { autoClose: false });
  } else if (res?.updatedRows) {
    toast.success(`已更改${res?.updatedRows}筆資料`, { autoClose: false });
  }

  if (res.errors) {
    toast.error(`總共 ${res.errors.length} 行錯誤資料`, { autoClose: false });
  }

  return toast.update(loadingRes, {
    render: `${CustomImportErrorMEssageHelper(res.errors[0].message)}
    ，請重試 (第${res.errors[0]?.index}行)
     `,
    icon: false,
    type: 'error',
    closeButton: true,
    autoClose: false,
  });
};

export const returnUpdatedIgnoreToastHelper = (
  loadingRes: string | number,
  res,
) => {
  if (res?.insertedRows) {
    toast.success(`已新增${res?.insertedRows}筆資料`, { autoClose: false });
  } else if (res?.updatedRows) {
    toast.success(`已更改${res?.updatedRows}筆資料`, { autoClose: false });
  }

  if (res.ignored) {
    toast.error(`總共 ${res.ignored.length} 行被忽略資料`, {
      autoClose: false,
    });
  }

  return toast.update(loadingRes, {
    render: `${CustomImportErrorMEssageHelper(
      res.ignored[0].message,
    )}，請重試 (第${res.ignored[0]?.index}行)`,
    icon: false,
    type: 'error',
    closeButton: true,
    autoClose: false,
  });
};

export const returnSuccessUpdatedHelper = (
  loadingRes: string | number,
  res,
) => {
  return toast.update(loadingRes, {
    render: `上載成功，${
      res?.insertedRows ? '已新增' + res?.insertedRows + '筆資料，' : ''
    } ${res?.updatedRows ? '已更改' + res.updatedRows + '筆資料，' : ''}`,
    icon: false,
    type: 'success',
    closeButton: true,
    autoClose: false,
  });
};

export const customImportErrorHelper = (toastLoading: string | number, res) => {
  if (res.errors && res.errors.length > 0) {
    return returnUpdatedErrorToastHelper(toastLoading, res);
  } else if (res.ignored && res.ignored.length > 0) {
    return returnUpdatedIgnoreToastHelper(toastLoading, res);
  } else {
    return returnSuccessUpdatedHelper(toastLoading, res);
  }
};
