import { API_URL, WEB_APP_URL } from 'app/config';
import {
  DisplayFileType,
  FileType,
  GetOneFileTypeRes,
  ShareDriveTokenResponse,
} from 'app/models';

const VIDEO_EXT = ['MP4', 'WEBM'];
const IMAGE_EXT = ['JPG', 'JPEG', 'GIF', 'PNG', 'WEBP', 'SVG'];
const PDF_EXT = ['PDF'];

export const getFileType = (fileName: string): DisplayFileType => {
  const nameArr = fileName.split('.');
  if (nameArr && nameArr.length > 1) {
    const extension = nameArr[nameArr.length - 1].toUpperCase();
    if (VIDEO_EXT.includes(extension)) {
      return 'video';
    } else if (IMAGE_EXT.includes(extension)) {
      return 'image';
    } else if (PDF_EXT.includes(extension)) {
      return 'pdf';
    }
  }
  return 'others';
};

export const getFilePreviewLink = (
  folderType: FileType,
  dir: string,
  fileName: string,
) => {
  const encodedPath = encodeURIComponent(`${dir}/${fileName}`);
  return `${API_URL}/v1/drive/file?folderType=${folderType}&driveType=public&path=${encodedPath}`;
};

export const fileCompareFunc = (a, b) => {
  if (a.type > b.type) {
    return -1;
  }
  if (a.type < b.type) {
    return 1;
  }
  return 0;
};

export const getShareDrivePath = (
  data: ShareDriveTokenResponse,
  file?: GetOneFileTypeRes,
) => {
  const encodedPath = encodeURIComponent(`${data.path}`);
  return `${WEB_APP_URL}/share?folder=${data.driveType}&type=${
    data.folderType
  }&path=${encodedPath}&token=${data.token}${
    file?.type && file?.type === 'File' ? `&fileName=${encodedPath}` : ''
  }${file?.url ? `&url=${file.url}` : ''}`;
};
