import { PAGE_SIZE } from 'app/config';
import { ListResult, OptionItems } from 'app/models';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

export interface UseAutoCompleteParams<P, R> {
  getListCall: (_params: P) => Promise<ListResult<R>>;
  getItemCall?: (id: number) => Promise<R>;
  keyExtracter: (item: R) => number | string;
  searchTextFieldName: keyof P;
  labelRender: (item: R) => OptionItems;
  errorText?: string;
  initialParams?: P;
  preventFirstLoad?: boolean;
  asc?: keyof P;
  desc?: keyof P;
}

export const useAutoComplete = <P, R>({
  getListCall,
  getItemCall,
  searchTextFieldName,
  keyExtracter,
  labelRender,
  errorText,
  initialParams,
  preventFirstLoad,
  asc,
  desc,
}: UseAutoCompleteParams<P, R>) => {
  const [dataList, setDataList] = useState<OptionItems[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [filter, setFilter] = useState<P>(initialParams as P);
  const shouldAutoLoad = useRef(preventFirstLoad === true ? false : true);
  const currentOffset = useRef(0);

  const getData = useCallback(async () => {
    try {
      const dataRes = await getListCall({
        ...filter,
        [searchTextFieldName]: searchText,
        limit: PAGE_SIZE,
        offset: 0,
        asc,
      });
      setDataList(dataRes.rows.map(labelRender));
    } catch (err) {
      console.error(err);
      toast.error('請檢查你的網絡。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, searchText]);

  const updateDataListWithItemCall = useCallback(async (id: number) => {
    try {
      let dataRes;
      if (getItemCall) {
        dataRes = await getItemCall(id);
      }
      const dataRes2 = await getListCall({
        ...filter,
        limit: 20,
        offset: 0,
      });
      const tmpDataList = dataRes2.rows
        .filter((item: R) => keyExtracter(item) !== id)
        .map(labelRender);
      setDataList([labelRender(dataRes), ...tmpDataList]);
      return dataRes;
    } catch (err) {
      console.error(err);
      toast.error('請檢查你的網絡。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldAutoLoad.current === true) {
      getData();
    }
    shouldAutoLoad.current = true;
  }, [getData]);

  const dataScrollToBottom = async () => {
    let offset = 0;
    if (dataList.length === 0) {
      offset = 0;
    } else {
      offset =
        Math.ceil(
          (dataList.length > 20 ? dataList.length - 1 : dataList.length) /
            PAGE_SIZE,
        ) * PAGE_SIZE;
    }

    try {
      if (offset !== currentOffset.current) {
        const dataRes = await getListCall({
          ...filter,
          [searchTextFieldName]: searchText,
          limit: PAGE_SIZE,
          offset: offset,
          asc,
        });
        setDataList(prev => [...prev, ...dataRes.rows.map(labelRender)]);
        currentOffset.current = offset;
      }
    } catch (err) {
      console.error(err);
      toast.error(errorText ? errorText : `加載列表失敗，請重試。`);
    }
  };

  return {
    filter,
    setFilter,
    searchText,
    setSearchText,
    dataList,
    setDataList,
    updateDataListWithItemCall,
    onMenuScrollToBottom: dataScrollToBottom,
  } as const;
};
