import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    /* height: 100%;
    width: 100%; */
  }

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #root {
    /* min-height: 100%;
    min-width: 100%; */
  }

  p,
  label {
    font-family: Georgia, Times, 'Times New Roman', serif;
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }
  .cursor-pointer {
    cursor: pointer;
  }

  .light-box.download-btn {
    width: 40px;
    height: 50px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
  }

  .light-box.download-btn:hover {
    opacity: 1;
  }

  .hover-brighten {
    opacity: 0.5;
    transition: all 0.4 ease-in-out;
  }

  .hover-brighten:hover {
    opacity: 1;
  }

  .fullscreen {
    width: 100%;
    height: 100%;
  }
  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .empty-button {
    background-color: transparent;
    border: unset;
  }
`;
