import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fileCompareFunc, getFileType } from 'app/helpers/DriveHelper';
import { DriveFile, FileType } from 'app/models';
import { GetFolderListParams, getFolderList } from 'app/services/DriveService';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AppThunk, RootState } from 'store';

interface DriveState {
  loading: boolean;
  currentFolder: DriveFile[];
  selectedFolderType: FileType | null;
  selectedPath: string;
  isError: boolean;
  isAllUploaded: boolean;
}

const initialState = {
  loading: false,
  currentFolder: [],
  selectedFolderType: null,
  selectedPath: '',
  isError: false,
  isAllUploaded: true,
} as DriveState;

const driveSlice = createSlice({
  name: 'drive',
  initialState,
  reducers: {
    getDriveDataLoadingStart(state) {
      state.loading = true;
    },
    getDriveDataSuccess(state) {
      state.loading = false;
      state.isError = false;
    },
    getDriveDataFail(state) {
      state.loading = false;
      state.isError = true;
    },
    setCurrentFolderData(state, action: PayloadAction<DriveFile[]>) {
      state.currentFolder = action.payload;
    },
    setFolderType(state, action: PayloadAction<FileType>) {
      state.selectedFolderType = action.payload;
    },
    setFolderPath(state, action: PayloadAction<string>) {
      state.selectedPath = action.payload;
    },
    goToFolder(state, action: PayloadAction<GetFolderListParams>) {
      state.selectedFolderType = action.payload.folderType;
      state.selectedPath = action.payload.path;
    },
    getUploadingFileSuccess(state) {
      state.isAllUploaded = true;
    },
    getUploadingFileFail(state) {
      state.isAllUploaded = true;
    },
    reset() {
      return initialState;
    },
  },
});

export const {
  getDriveDataLoadingStart,
  getDriveDataSuccess,
  getDriveDataFail,
  setCurrentFolderData,
  setFolderType,
  setFolderPath,
  goToFolder,
  getUploadingFileSuccess,
  getUploadingFileFail,
} = driveSlice.actions;
export default driveSlice.reducer;

export const useDriveData = () => {
  const dispatch = useDispatch();
  const { loading, currentFolder, selectedFolderType, selectedPath, isError } =
    useSelector((rootState: RootState) => rootState.drive);

  const getCurrentFolderData = useCallback(async () => {
    if (selectedFolderType) {
      try {
        dispatch(getDriveDataLoadingStart());
        const driveRes = await getFolderList({
          driveType: 'public',
          folderType: selectedFolderType,
          path: selectedPath,
        });
        const newFolderData = driveRes
          .map(res => ({
            ...res,
            fileType: getFileType(res.fileName),
          }))
          .sort(fileCompareFunc);
        dispatch(setCurrentFolderData(newFolderData));
        dispatch(getDriveDataSuccess());
      } catch (err) {
        console.error(err);
        toast.warning('請檢查你的網絡。');
        dispatch(getDriveDataFail());
      }
    }
  }, [dispatch, selectedFolderType, selectedPath]);

  useEffect(() => {
    getCurrentFolderData();
  }, [getCurrentFolderData]);

  return {
    loading,
    currentFolder,
    selectedFolderType,
    selectedPath,
    isError,
  };
};

export const getUpdateCurrentFolder =
  (): AppThunk => async (dispatch, getState) => {
    const { selectedFolderType, selectedPath } = getState().drive;
    if (selectedFolderType) {
      try {
        dispatch(getDriveDataLoadingStart());
        const driveRes = await getFolderList({
          driveType: 'public',
          folderType: selectedFolderType,
          path: selectedPath,
        });
        const newFolderData = driveRes
          .map(res => ({
            ...res,
            fileType: getFileType(res.fileName),
          }))
          .sort(fileCompareFunc);
        dispatch(setCurrentFolderData(newFolderData));
        dispatch(getDriveDataSuccess());
      } catch (err) {
        console.error(err);
        toast.warning('請檢查你的網絡。');
        dispatch(getDriveDataFail());
      }
    }
  };
