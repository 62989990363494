/**
 *
 * Asynchronously loads the component for SurveyResponseListPage
 *
 */
import { lazyLoad } from 'utils/loadable';

export const SurveyResponseListPage = lazyLoad(
  () => import('./index'),
  module => module.SurveyResponseListPage,
);
