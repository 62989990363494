import { AutoCompleteSelect, SearchBar } from 'app/components/Filter';
import { Pagination, Table } from 'app/components/Table';
import { PAGE_SIZE } from 'app/config';
import { useAutoComplete } from 'app/hooks';
import { CustomTableTag, OptionItems, PreAgentData, User } from 'app/models';
import {
  GetAllUserPreagentListParams,
  getAllUserPreagentList,
} from 'app/services/PreagentServices';
import { GetUserListParams, getUserList } from 'app/services/UserService';
import _ from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';

import SelectPreagentListColumns from './SelectPreagentListColumns';
import SelectUserListColumn from './SelectUserListColumn';

interface SelectUserTableProps {
  selectedUser: Record<string, CustomTableTag>;
  setSelectedUser: (userMap: Record<string, CustomTableTag>) => void;
  saveOnPress: () => void;
  isSingleSelect?: boolean;
  isPreagent?: boolean;
}

export const SelectUserTable = ({
  selectedUser,
  setSelectedUser,
  saveOnPress,
  isSingleSelect,
  isPreagent,
}: SelectUserTableProps) => {
  const [filter, setFilter] = useState<GetUserListParams>({
    limit: PAGE_SIZE,
    offset: 0,
  });
  const [userList, setUserList] = useState<User[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>('');

  const [preagentList, setPreagentList] = useState<PreAgentData[]>([]);
  const [preagentCount, setPreagentCount] = useState<number>(0);
  const [preagentPage, setPreagentPage] = useState<number>(1);
  const [preagentFilter, setPreagentFilter] =
    useState<GetAllUserPreagentListParams>({
      limit: PAGE_SIZE,
      offset: 0,
    });
  const [studentNumberParams, setStudentNumberParams] = useState<string>('');
  const [searchUserParams, setSearchUserParams] = useState<OptionItems | null>(
    null,
  );

  const {
    setSearchText: setUserText,
    dataList: userListAutoComplete,
    onMenuScrollToBottom: onEventMenuScrollToBottom,
  } = useAutoComplete<GetUserListParams, User>({
    getListCall: getUserList,
    labelRender: (user: User) => ({
      label: `#${user.userId} | ${user?.displayName || '-'} | ${
        user.agentLicenseNumber
      }`,
      value: user.userId.toString(),
    }),
    keyExtracter: (user: User) => user.userId,
    searchTextFieldName: 'name',
  });

  const getUserData = useCallback(async () => {
    if (filter) {
      setLoading(true);
      try {
        const userRes = await getUserList(filter);
        const { rows, count } = userRes;
        setUserList(rows);
        setCount(count);
      } catch (err) {
        console.log(err);
        toast.error('獲取會員資料失敗，請重試.');
      } finally {
        setLoading(false);
      }
    }
  }, [filter]);

  useEffect(() => {
    getUserData();
  }, [getUserData, filter]);

  const getPreagentData = useCallback(async () => {
    setLoading(true);
    if (preagentFilter) {
      try {
        const tempFilter = { ...preagentFilter };
        const preagentRes = await getAllUserPreagentList(tempFilter);
        const { rows, count } = preagentRes;
        setPreagentList(rows);
        setPreagentCount(count);
      } catch (err) {
        console.log(err);
        toast.error('獲取準成員資料失敗，請重試');
      } finally {
        setLoading(false);
      }
    }
  }, [preagentFilter]);

  useEffect(() => {
    getPreagentData();
  }, [getPreagentData]);

  const pageOnChange = (page: number) => {
    if (isPreagent) {
      setPreagentPage(page);
      setPreagentFilter({
        ...preagentFilter,
        offset: (page - 1) * PAGE_SIZE,
      });
    } else {
      setPage(page);
      setFilter({
        ...filter,
        offset: (page - 1) * PAGE_SIZE,
      });
    }
  };

  const checkAllOnClick = () => {
    if (isSingleSelect) {
      setSelectedUser({});
    } else {
      const tempSelected = { ...selectedUser };
      let count = 0;
      if (isPreagent) {
        preagentList.forEach(preagent => {
          if (tempSelected[preagent.preAgentId]) {
            count++;
          }
        });
      } else {
        userList.forEach(user => {
          if (tempSelected[user.userId]) {
            count++;
          }
        });
      }
      if (isPreagent) {
        if (count === preagentList.length && preagentList.length > 0) {
          preagentList.forEach(preagent => {
            delete tempSelected[preagent.preAgentId];
          });
        } else {
          preagentList.forEach(preagent => {
            tempSelected[preagent.preAgentId] = preagent;
          });
        }
      } else {
        if (count === userList.length && userList.length > 0) {
          userList.forEach(user => {
            delete tempSelected[user.userId];
          });
        } else {
          userList.forEach(user => {
            tempSelected[user?.userId!] = user;
          });
        }
      }
      setSelectedUser(tempSelected);
    }
  };

  const userOnCheck = (user: CustomTableTag) => {
    if (isSingleSelect) {
      const tempUserMap = {};
      if (isPreagent) {
        tempUserMap[user?.preAgentId!] = user;
      } else {
        tempUserMap[user?.userId!] = user;
      }
      setSelectedUser(tempUserMap);
    } else {
      const tempUserMap = { ...selectedUser };
      if (isPreagent && tempUserMap[user?.preAgentId!]) {
        delete tempUserMap[user?.preAgentId!];
      } else if (tempUserMap[user?.userId!]) {
        delete tempUserMap[user?.userId!];
      } else {
        if (isPreagent) {
          tempUserMap[user?.preAgentId!] = user;
        } else {
          tempUserMap[user?.userId!] = user;
        }
      }
      setSelectedUser(tempUserMap);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounchSearch = useCallback(
    _.debounce(name => {
      const filter = {
        limit: PAGE_SIZE,
        offset: 0,
        name,
      };
      if (isPreagent) {
        setPreagentFilter(filter);
      } else {
        setFilter(filter);
      }
    }, 300),
    [],
  );

  const nameOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    debounchSearch(e.target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const studentNumeDebounceSearch = useCallback(
    _.debounce(studentNumber => {
      const filter = {
        limit: PAGE_SIZE,
        offset: 0,
        studentNumber: studentNumber,
      };
      setPreagentFilter(filter);
    }, 500),
    [],
  );

  const studentNumeOnChange = e => {
    setStudentNumberParams(e.target.value);
    studentNumeDebounceSearch(e.target.value);
  };

  const userIdAutoCompleteOnChange = e => {
    const tempFilter = { ...preagentFilter };
    if (e) {
      tempFilter.userId = e.value;
      setSearchUserParams(e);
    } else {
      delete tempFilter.userId;
      // setIsDefaultSearchUserId?.({});
      setSearchUserParams(null);
    }

    setPreagentFilter(tempFilter);
  };

  const sortOnChange = e => {
    if (isPreagent) {
      const tempFilter = { ...preagentFilter };
      delete tempFilter.asc;
      delete tempFilter.desc;
      if (e) {
        if (e.asc) {
          tempFilter.asc = e.asc;
        } else if (e.desc) {
          tempFilter.desc = e.desc;
        } else {
          tempFilter.asc = undefined;
          tempFilter.desc = undefined;
        }
      }
      setPreagentFilter(tempFilter);
    } else {
      const tempFilter = { ...filter };
      delete tempFilter.asc;
      delete tempFilter.desc;
      if (e) {
        if (e.asc) {
          tempFilter.asc = e.asc;
        } else if (e.desc) {
          tempFilter.desc = e.desc;
        } else {
          tempFilter.asc = undefined;
          tempFilter.desc = undefined;
        }
      }
      setFilter(tempFilter);
    }
  };

  return (
    <>
      <Row className="align-items-md-center mb-3">
        <Col className="w-100 d-flex flex-row align-items-center justify-content-end">
          <Button
            type="button"
            color="primary"
            onClick={() => {
              saveOnPress();
            }}
          >
            選擇
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {isPreagent ? (
            <Row>
              <Col md={4}>
                <AutoCompleteSelect
                  name="userId"
                  label="搜尋會員"
                  placeholder="搜尋會員"
                  customValue={searchUserParams}
                  onInputChange={setUserText}
                  options={userListAutoComplete}
                  onMenuScrollToBottom={onEventMenuScrollToBottom}
                  isClearable
                  onChange={e => userIdAutoCompleteOnChange(e)}
                />
              </Col>
              <Col md={4}>
                <SearchBar
                  label="搜尋準會員學生編號"
                  placeholder="搜尋準會員學生編號"
                  searchField="studentNumber"
                  onChange={studentNumeOnChange}
                  value={studentNumberParams}
                />
              </Col>
            </Row>
          ) : (
            <Col md={4}>
              <SearchBar
                label="搜尋會員名稱"
                placeholder="搜尋會員名稱"
                searchField="name"
                onChange={nameOnChange}
                value={name}
              />
            </Col>
          )}
        </Col>
        <Col md={1}>
          {loading ? (
            <div className="pb-3 h-100 d-flex flex-column align-items-start justify-content-end">
              <i className="bx bx-loader-circle bx-spin text-primary mb-1" />
            </div>
          ) : null}
        </Col>
      </Row>

      <Row>
        <Col xl="12">
          <div className="table-responsive">
            <Table
              columns={
                isPreagent
                  ? SelectPreagentListColumns()
                  : SelectUserListColumn()
              }
              keyField={isPreagent ? 'preAgentId' : 'userId'}
              data={isPreagent ? preagentList : userList}
              checkableConfig={{
                isCheckBoxShow: () => true,
                onCheck: userOnCheck,
                selected: selectedUser,
                checkAll: checkAllOnClick,
              }}
              sortOnChange={e => sortOnChange(e)}
            />
          </div>
        </Col>
      </Row>
      <Row className="align-items-md-center mt-3">
        <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
          <Pagination
            count={isPreagent ? preagentCount : count}
            current={isPreagent ? preagentPage : page}
            onChange={pageOnChange}
          />
        </Col>
      </Row>
      <Row className="align-items-md-center mt-3">
        <Col className="w-100 d-flex flex-row align-items-center justify-content-end">
          <Button
            type="button"
            color="primary"
            onClick={() => {
              saveOnPress();
            }}
          >
            選擇
          </Button>
        </Col>
      </Row>
    </>
  );
};
