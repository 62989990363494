/**
 *
 * UserTagForm
 *
 */
import { TableCount } from 'app/components/Common';
import Breadcrumbs from 'app/components/Common/Breadcrumb2';
import { InputField } from 'app/components/Form';
import { Dialog } from 'app/components/Modal';
import { Pagination, Table } from 'app/components/Table';
import { APP_NAME, PAGE_SIZE } from 'app/config';
import { CustomTableTag, PreAgentData, SortType, User } from 'app/models';
import {
  GetUserListByTagNameParams,
  createOneUserTag,
  deleteOneUserTag,
  getUserListByTagName,
  updateOneUserTag,
} from 'app/services/UserService';
import { Form, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { RootState } from 'store';
import { changePreloader } from 'store/Layout';
import * as Yup from 'yup';

import SelectUserListColumn from './SelectUserListColumn';
import { SelectUserTable } from './SelectUserTable';
import './SelectUserTable.scss';

// add service
// import {} from 'app/services/';

// form

// form schema
const Schema = Yup.object().shape({
  tagName: Yup.string()
    .required('必填項目')
    .min(2, '最少要2個字元')
    .max(100, '不可多於100個字元'),
  users: Yup.array()
    .nullable()
    .when('type', (type, passSchema) =>
      type === 0
        ? passSchema.min(1, '必填項目').required('必填項目')
        : passSchema,
    ),
});

// form type
type FormItem = {
  tagName: string;
  users: User[];
  preagents: PreAgentData[];
  type: number;
};

// form initialValues
const FORM_ITEM = {
  tagName: '',
  users: [],
  preagents: [],
  type: 0,
};

interface UserTagFormParams {
  tagName: string;
}

export const UserTagFormPage = () => {
  const dispatch = useDispatch();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const history = useHistory();
  const params = useParams<UserTagFormParams>();

  const [originalUserTag, setOriginalUserTag] = useState<string | null>(null);
  const [form, setForm] = useState<FormItem>(FORM_ITEM);

  const [loading, setLoading] = useState<boolean>(false);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<
    Record<string, CustomTableTag>
  >({});

  const [selectUserModalVisible, setSelectUserModalVisible] =
    useState<boolean>(false);

  const [filter, setFilter] = useState<GetUserListByTagNameParams>({
    limit: PAGE_SIZE,
    offset: 0,
  });
  const [userList, setUserList] = useState<User[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);

  const initForm = useCallback(async () => {
    dispatch(changePreloader(true));
    const { tagName: TagName } = params;
    // update form: get form data by id
    try {
      if (TagName) {
        const tag = decodeURIComponent(TagName);
        setOriginalUserTag(TagName);
        setForm({ tagName: tag, users: [], preagents: [], type: 1 });
        dispatch(changePreloader(false));
      } else {
        dispatch(changePreloader(false));
      }
    } catch (err) {
      dispatch(changePreloader(false));
      console.log(err);
      toast.warning('請檢查你的網絡。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    initForm();
  }, [initForm]);

  const getUserList = useCallback(async () => {
    const { tagName: TagName } = params;
    try {
      if (TagName) {
        const userRes = await getUserListByTagName(TagName, filter);
        setUserList(userRes.rows);
        setCount(userRes.count);
      }
    } catch (err) {
      console.log(err);
      toast.warning('請檢查你的網絡。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    getUserList();
  }, [getUserList, filter]);

  // create
  const createUserTag = async (values: FormItem) => {
    setLoading(true);
    const { tagName, users } = values;
    try {
      await createOneUserTag(
        tagName,
        users.map(user => user.userId),
      );
      setLoading(false);
      toast.success('新增Tag成功。');
      history.push('/user-tags');
    } catch (err: any) {
      setLoading(false);
      toast.warning('新增失敗，請重試。');
    }
  };

  // update
  const updateUserTag = async (values: FormItem) => {
    setLoading(true);
    const { tagName, users } = values;
    try {
      await updateOneUserTag(originalUserTag!, tagName);
      await createOneUserTag(
        tagName,
        users.map(user => user.userId),
      );
      setLoading(false);
      toast.success('編輯Tag成功。');
      history.push('/user-tags');
    } catch (err) {
      setLoading(false);
      toast.warning('編輯失敗，請重試。');
      console.log(err);
    }
  };

  // form submit
  const onSubmit = async (values: FormItem) => {
    if (originalUserTag) {
      updateUserTag(values);
    } else {
      createUserTag(values);
    }
  };

  const deleteConfirm = async () => {
    setLoading(false);
    try {
      await deleteOneUserTag(originalUserTag!);
      toast.success('成功刪除會員Tag');
      setLoading(false);
      setDeleteVisible(false);
      history.replace('/user-tags');
    } catch (err) {
      toast.warning('刪除失敗，請重試。');
      setLoading(false);
      setDeleteVisible(false);
    }
  };

  const userSortOnChange = (sort: SortType | null) => {
    const tempFilter = { ...filter };
    if (sort && sort.asc) {
      delete tempFilter.desc;
      tempFilter.asc = sort.asc as keyof User;
    } else if (sort && sort.desc) {
      delete tempFilter.asc;
      tempFilter.desc = sort.desc as keyof User;
    } else {
      delete tempFilter.asc;
      delete tempFilter.desc;
    }
    setFilter(tempFilter);
  };

  const pageOnChange = (page: number) => {
    setPage(page);
    const tempFilter = { ...filter };
    tempFilter.offset = (page - 1) * PAGE_SIZE;
    setFilter(tempFilter);
  };

  const TITLE = `${`${originalUserTag ? '編輯' : '新增'}`} 會員Tag`;

  const BreadcrumbsOptions = [
    { title: '會員', path: '/user-tags' },
    { title: '會員Tag列表', path: '/user-tags' },
    { title: TITLE, path: '#' },
  ];

  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>
            {TITLE} | {APP_NAME}
          </title>
        </Helmet>
        <Container fluid={true}>
          <Breadcrumbs title={TITLE} breadcrumbItems={BreadcrumbsOptions} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle>會員Tag資料</CardTitle>
                  {!isPreloader ? (
                    <div className="p-2">
                      <Formik
                        initialValues={form}
                        validationSchema={Schema}
                        onSubmit={onSubmit}
                        enableReinitialize
                      >
                        {({
                          setFieldValue,
                          values,
                          errors,
                          touched,
                          dirty,
                        }) => (
                          <Form className="form-horizontal">
                            <Row>
                              <Col lg={6}>
                                <InputField
                                  name="tagName"
                                  label="Tag"
                                  placeholder="e.g. VIP 會員"
                                  type="text"
                                />
                              </Col>
                            </Row>

                            <Row>
                              <Col lg={6}>
                                <div className="w-100 d-flex flex-column align-items-end justify-content-start">
                                  <div className="d-flex">
                                    <Button
                                      type="button"
                                      color="warning"
                                      onClick={() =>
                                        setSelectUserModalVisible(true)
                                      }
                                    >
                                      選擇會員
                                    </Button>
                                  </div>
                                  {errors.users && touched.users ? (
                                    <div className="text-danger">
                                      請選擇會員
                                    </div>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              {values.users.map((user, index) => (
                                <Row key={user.userId}>
                                  <Col lg={6} className="mt-2">
                                    <CardTitle>已選擇會員名單：</CardTitle>
                                    <div className="d-flex flex-row align-items-center justify-content-between w-100 py-2">
                                      <div>
                                        <div className="font-size-14 text-black">
                                          {user.displayName}
                                        </div>
                                        <div className="font-size-12 text-muted">
                                          {user.jobTitle}
                                        </div>
                                      </div>
                                      <button
                                        className="user-list-delete-btn"
                                        type="button"
                                        onClick={() => {
                                          const tempUser = [
                                            ...values.users,
                                          ].slice();
                                          tempUser.splice(index, 1);
                                          setFieldValue('users', tempUser);
                                        }}
                                      >
                                        <i className="mdi mdi-trash-can-outline font-size-16" />
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              ))}
                              {values.preagents.map((preagent, index) => (
                                <Row key={preagent.preAgentId}>
                                  <Col lg={6} className="mt-2">
                                    <CardTitle>已選擇準會員名單：</CardTitle>
                                    <div className="d-flex flex-row align-items-center justify-content-between w-100 py-2">
                                      <div>
                                        <div className="font-size-14 text-black">
                                          {preagent.firstName}
                                        </div>
                                        <div className="font-size-12 text-muted">
                                          {preagent.lastName}
                                        </div>
                                      </div>
                                      <button
                                        className="user-list-delete-btn"
                                        type="button"
                                        onClick={() => {
                                          const tempPreagent = [
                                            ...values.users,
                                          ].slice();
                                          tempPreagent.splice(index, 1);
                                          setFieldValue(
                                            'preagents',
                                            tempPreagent,
                                          );
                                        }}
                                      >
                                        <i className="mdi mdi-trash-can-outline font-size-16" />
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              ))}
                            </Row>

                            <div className="mt-3 d-flex flex-row-reverse">
                              <Button
                                type="submit"
                                color="primary"
                                className="ms-1"
                                disabled={loading}
                              >
                                {loading ? (
                                  <i className="bx bx-loader-circle bx-spin" />
                                ) : (
                                  '提交'
                                )}
                              </Button>
                              {originalUserTag ? (
                                <Button
                                  type="button"
                                  color="danger"
                                  className="ms-1"
                                  onClick={() => setDeleteVisible(true)}
                                >
                                  刪除
                                </Button>
                              ) : null}
                            </div>

                            <Dialog
                              visible={selectUserModalVisible}
                              title="選擇會員"
                              onClose={() => setSelectUserModalVisible(false)}
                              loading={loading}
                              size="lg"
                            >
                              <SelectUserTable
                                selectedUser={selectedUser}
                                setSelectedUser={setSelectedUser}
                                saveOnPress={() => {
                                  setFieldValue(
                                    'users',
                                    Object.keys(selectedUser).map(
                                      key => selectedUser[key],
                                    ),
                                  );
                                  setSelectUserModalVisible(false);
                                }}
                              />
                            </Dialog>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>

            {userList && userList.length ? (
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm={8}>
                        <CardTitle>{originalUserTag}的會員</CardTitle>
                      </Col>
                      <Col
                        sm={4}
                        className="d-flex align-self-end justify-content-end"
                      >
                        <TableCount count={count} title="擁有此Tag會員總人數" />
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <Table
                            columns={SelectUserListColumn()}
                            keyField="userId"
                            data={userList || []}
                            sortOnChange={userSortOnChange}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-3">
                      <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                        <Pagination
                          count={count}
                          current={page}
                          onChange={pageOnChange}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ) : null}
          </Row>
        </Container>
        <Dialog
          visible={deleteVisible!}
          title="刪除會員Tag"
          onClose={() => setDeleteVisible?.(false)}
          loading={loading}
          onConfirm={deleteConfirm}
        >
          <p>{`確定要刪除會員Tag (${originalUserTag}) 嗎?`}</p>
        </Dialog>
      </div>
    </>
  );
};
