import { OptionItems } from 'app/models';
import { FieldHookConfig, useField } from 'formik';
import React from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';

interface OtherProps {
  label: string;
  horizontal?: boolean;
  placeholder?: string;
  options: OptionItems[];
  isClearable?: boolean;
  disabled?: boolean;
  inputValue?: string;
  onInputChange?: (newValue: string) => void;
}

const MultipleSelectField = (
  props: OtherProps & FieldHookConfig<OptionItems[]>,
) => {
  const [field, meta, helpers] = useField(props);

  const {
    label,
    placeholder,
    options,
    isClearable,
    disabled,
    inputValue,
    onInputChange,
  } = props;

  return (
    <div
      className={`mb-2 form-group ${
        meta.touched && meta.error ? 'has-danger' : ''
      }`}
    >
      {label ? <Label>{props.label}</Label> : null}
      <Select
        isMulti={true}
        placeholder={placeholder}
        options={options}
        classNamePrefix="select2-selection"
        {...field}
        onChange={e => {
          helpers.setValue(e);
        }}
        isClearable={isClearable}
        isDisabled={disabled}
        inputValue={inputValue}
        onInputChange={onInputChange}
      />
      {meta.touched && meta.error ? (
        <div className="text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default MultipleSelectField;
