import {
  AttendeesInEventApplicationData,
  Event,
  EventApplicationData,
  EventApplicationStatus,
  EventAttachmentDriveType,
  EventAttachmentFolderType,
  EventMaterialDriveType,
  EventMaterialFolderType,
  EventPaymentOptions,
  EventPaymentStatus,
  EventStatus, // EventCategory,
  Filter,
  ListResult,
} from 'app/models';

import Axios from '.';

/**
 * Get all Event list (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/events)
 */

export interface GetEventListParams extends Filter<Event> {
  eventTitle?: string;
  memberOnly?: boolean;
  isOnline?: boolean;
  isFull?: boolean;
  allowRegistration?: boolean;
  eventStatus?: EventStatus;
  startDate: string;
  endDate: string;
}

export const getEventList = async (params: GetEventListParams) => {
  const { data } = await Axios.get<ListResult<Event>>('/events', {
    params,
  });
  return data;
};

/**
 * Get one Event (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/events/-eventId-)
 */

export const getOneEvent = async (eventId: number | string) => {
  const { data } = await Axios.get<Event>(`/events/${eventId}`);
  return data;
};

/**
 * create one Event (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/events)
 */

export interface CreateOneEventParams {
  eventTitle: string;
  eventDescription: string | null;
  startDate: string | null;
  endDate: string | null;
  registrationDeadline: string | null;
  memberOnly: boolean;
  eventLocation: string | null;
  eventQuota: string;
  isOnline: boolean;
  allowGuest: boolean;
  zoomRoomNumber: string | null;
  zoomRoomPassword: string | null;
  images: any;
  eventReminder: string | null;
  eventAttachmentFolderType: EventAttachmentFolderType;
  eventAttachmentDriveType: EventAttachmentDriveType;
  eventAttachmentPath: string;

  materialFolderType: EventMaterialFolderType | null;
  materialDriveType: EventMaterialDriveType | null;
  materialPath: string | null;

  pointEarnAfterApply: number | null;
  pointEarnAfterCheckIn: number | null;

  paymentOptions: EventPaymentOptions[];
  publishedDate: string;
  permissionTags: string[];
  permissionRankCodes: string[];
  surveyId: number | null;
  maxNoOfGuest: number | null;
}

export const createOneEvent = async (params: CreateOneEventParams) => {
  const { data } = await Axios.post<Event>(`/events`, params);
  return data;
};

/**
 * update one Event (https://svgroup-app-dev.appicidea.com/doc/v2#patch-/v1/events/-eventId-)
 */

export interface UpdateOneEventParams {}

export const updateOneEvent = async (
  eventId: number | string,
  params: UpdateOneEventParams,
) => {
  const { data } = await Axios.patch<Event>(`/events/${eventId}`, params);
  return data;
};

/**
 * delete one Event (https://svgroup-app-dev.appicidea.com/doc/v2#delete-/v1/events/-eventId-)
 */

export const deleteOneEvent = async (eventId: number | string) => {
  const { data } = await Axios.delete<string>(`/events/${eventId}`);
  return data;
};

// Event Appl Data--------------------------------------------------------------------------------------------------------------------

/**
 * Get all Event Application list (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/events/applications)
 */

export interface GetAllEventApplicationListParams
  extends Filter<EventApplicationData> {
  status?: 'reserved' | 'pendingReview' | 'completed';
  // titleLike?: string;
  userId?: number;
}

export const getAllEventApplicationList = async (
  // eventId: number | string,
  params?: GetAllEventApplicationListParams,
) => {
  const { data } = await Axios.get<ListResult<EventApplicationData>>(
    `/events/applications`,
    {
      params,
    },
  );
  return data;
};

/**
 * Get One Event list Application (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/events/-eventId-/applications)
 */

export interface GetOneEventListApplicationParams
  extends Filter<EventApplicationData> {
  status?: 'reserved' | 'pendingReview' | 'completed' | 'canceled';
  paymentStatus?: EventPaymentStatus;
}

export const getOneListEventApplication = async (
  eventId: number | string,
  params?: GetOneEventListApplicationParams,
) => {
  const { data } = await Axios.get<ListResult<EventApplicationData>>(
    `/events/${eventId}/applications`,
    {
      params,
    },
  );
  return data;
};

/**
 * Get All Attendees in Event Application (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/events/-eventId-/attendees)
 */

type StatusFilter = 'reserved' | 'pendingReview' | 'completed' | 'canceled';

export interface GetAttendeesInEventListApplicationParams
  extends Filter<AttendeesInEventApplicationData> {
  status?: StatusFilter[];
  paymentStatus?: EventPaymentStatus;
  hasCheckInRecord?: boolean;
  name?: string;
}

export const getAttendeesInEventApplication = async (
  eventId: number | string,
  params?: GetAttendeesInEventListApplicationParams,
) => {
  const { data } = await Axios.get<ListResult<AttendeesInEventApplicationData>>(
    `/events/${eventId}/attendees`,
    {
      params,
    },
  );
  return data;
};

/**
 * Get One Attendee in Event Application (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/events/-eventId-/attendees/-eventAttendeeId-)
 */

export const getOneEventAttendee = async (
  eventId: number | string,
  eventAttendeeId: number | string,
) => {
  const { data } = await Axios.get<AttendeesInEventApplicationData>(
    `/events/${eventId}/attendees/${eventAttendeeId}`,
  );
  return data;
};

/**
 * Update One Attendee (https://svgroup-app-dev.appicidea.com/doc/v2#patch-/v1/events/-eventId-/attendees/-eventAttendeeId-)
 */

export interface UpdateOneEventAttendeeParams {
  name?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  checkInDateTime: string | null;
  checkOutDateTime: string | null;
}

export const updateOneEventAttendee = async (
  eventId: number | string,
  eventAttendeeId: number | string,
  params: UpdateOneEventAttendeeParams,
) => {
  const { data } = await Axios.patch<UpdateOneEventAttendeeParams>(
    `/events/${eventId}/attendees/${eventAttendeeId}`,
    params,
  );
  return data;
};

/**
 * Update One Event list Application (https://svgroup-app-dev.appicidea.com/doc/v2#patch-/v1/events/application/-eventApplicationId-)
 */

export interface UpdateOneEventApplicationParams {
  status: EventApplicationStatus;
}

export const updateOneEventApplication = async (
  eventApplicationId: number | string,
  params: UpdateOneEventApplicationParams,
) => {
  const { data } = await Axios.patch<EventApplicationData>(
    `/events/application/${eventApplicationId}`,
    params,
  );
  return data;
};

/**
 * Create new event application (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/events/-eventId-/applications)
 */

export interface CreateOneEventApplicationParams {
  userId: number;
  paymentOption: {
    cash: number;
    point: number;
  };
  Attendees?: {
    userId: number | string | null;
    name: string;
    phoneNumber: string;
    email: string;
  }[];
}

export const createOneEventApplication = async (
  eventId,
  params: CreateOneEventApplicationParams,
) => {
  const { data } = await Axios.post<any>(
    `/events/${eventId}/applications`,
    params,
  );
  return data;
};

/**
* *  export Event Attendee list Excel (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/events/-eventId-/attendee.xlsx)
+*/

export interface exportEventAttendeeListExcelParams {
  status: EventApplicationStatus;
  paymentStatus: EventPaymentStatus;
}

export const exportEventAttendeeListExcel = async (eventId: number) => {
  const { data } = await Axios.get<null>(`/events/${eventId}/attendee.xlsx`);
  return data;
};

/**
* *  export Event Application list Excel (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/events/-eventId-/attendee.xlsx)
+*/

export const exportEventApplicationListExcel = async (eventId: number) => {
  const { data } = await Axios.get<null>(`/events/${eventId}/application.xlsx`);
  return data;
};

/**
* *  import Event Application list Excel (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/events/-eventId-/attendee.xlsx)
+*/

export const importEventApplicationListExcel = async (
  eventId: number,
  params: FormData,
) => {
  const { data } = await Axios.post<any>(
    `/events/${eventId}/applications/import`,
    params,
  );
  return data;
};
