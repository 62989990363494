import { Admin, AdminLoginPayload, Filter, ListResult } from '../models';
import Axios from './';

/**
* * get current admin profile (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/admins/me/profile)
+*/

export const getCurrentAdminProfile = async () => {
  const { data } = await Axios.get<Admin>('/admins/me/profile');
  return data;
};

/**
* * admin login (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/admins/passwordAuth/SignIn)
+*/

export const adminLogin = async (para: AdminLoginPayload) => {
  const { data } = await Axios.post<string>(
    '/admins/passwordAuth/SignIn',
    para,
  );
  return data;
};

/**
* * admin logout (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/admins/me/logout)
+*/

export const adminLogout = async () => {
  const { data } = await Axios.post<any>('/admins/me/logout');
  return data;
};

export interface GetAdminListParams extends Filter<Admin> {
  displayName: string;
}

export const getAdminList = async (params: GetAdminListParams) => {
  const { data } = await Axios.get<ListResult<Admin>>('/admins', {
    params,
  });
  return data;
};

export interface CreateNewAdminParams {
  loginName?: string;
  password: string | null;
  displayName: string;
  role: string;
}

export const createOneAdmin = async (param: CreateNewAdminParams) => {
  const { data } = await Axios.post<Admin>('/admins', param);
  return data;
};

export const getSingleAdmin = async (adminId: string | number) => {
  const { data } = await Axios.get<Admin>(`/admins/${adminId}`);
  return data;
};

export const updateAdmin = async (
  adminId: string | number,
  param: Partial<CreateNewAdminParams>,
) => {
  const { data } = await Axios.patch<null>(`/admins/${adminId}`, param);
  return data;
};

export const deleteAdmin = async (adminId: string | number) => {
  const { data } = await Axios.delete<string>(`/admins/${adminId}`);
  return data;
};

// export const getProfile = async () => {
//   const { data } = await Axios.get<ListResult<Admin>>('/admin/profile');
//   return data;
// };

export const resetAdminPassword = async (
  adminId: number | string,
  para: { newPassword?: string },
) => {
  const { data } = await Axios.post<string>(
    `/admins/${adminId}/resetPassword`,
    para,
  );
  return data;
};

export const logout = async () => {
  const { data } = await Axios.post('/admins/me/logout');
  return data;
};

// export interface GetCompanyListParams extends Filter<> {
//   name?: string;
// }

// export const getCompaniesForCompanyAdmin = async (
//   params: GetCompanyListParams,
// ) => {
//   const { data } = await Axios.get<ListResult<CompanyResonse>>(
//     'admins/me/companies',
//     {
//       params,
//     },
//   );
//   return data;
// };
