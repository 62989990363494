import { TimeStamp } from './Common';

export enum FileTypeOption {
  'image' = '相片',
  'video' = '影片',
  'others' = '文件',
  'private' = '管理員專用',
}

export enum DriveFileTypeOption {
  'image' = '相片庫',
  'video' = '影片庫',
  'others' = '文件庫',
  'private' = '管理員專用',
}

export type FileType = keyof typeof FileTypeOption;

export interface DriveObject {
  type: DriveObjectType;
  folderType: FileType;
  driveType: DriveType;
  fileName: string;
  isBookmark: boolean;
  dir: string;
}

export interface DriveFile extends DriveObject {
  fileType: DisplayFileType;
}

export type DisplayFileType = FileType | 'pdf';

export type DriveObjectType = 'Folder' | 'File';

export type DriveType = 'public' | 'private';

export interface UploadFileItem {
  name: string;
  type: FileType;
  status: FileUploadStatusType;
  file: File;
}

export type FileUploadStatusType = 'success' | 'fail' | 'uploading';

export enum FileIconsOption {
  'image' = 'mdi mdi-image font-size-18 align-middle me-2 text-success',
  'video' = 'mdi mdi-file-video font-size-18 me-2 text-danger',
  'others' = 'mdi mdi-file-document-multiple font-size-18 me-2 text-secondary',
  'pdf' = 'mdi mdi-file-pdf font-size-18 me-2 text-info',
}

export interface FileTypeResponse {
  type: DriveObjectType;
  url: string;
}

export interface ShareDriveTokenResponse extends TimeStamp {
  driveShareTokenId: number;
  driveType: DriveType;
  folderType: DriveFileTypeOption;
  path: string;
  token: string;
  userId: number;
}

export interface GetOneFileTypeRes {
  type: DriveObjectType;
  url: string | null;
}
