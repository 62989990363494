import { Filter, ListResult } from 'app/models';
import { CompanyActivityData } from 'app/models/CompanyActivity';

import Axios from './';

/**
* * Get Company Activity list (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/companyActivities)
+*/

export interface GetCompanyActivityListParams
  extends Filter<CompanyActivityData> {
  title?: string;
  isOnline?: boolean;
  startDate?: string;
  endDate?: string;
}

export const getCompanyActivityList = async (
  params: GetCompanyActivityListParams,
) => {
  const { data } = await Axios.get<ListResult<CompanyActivityData>>(
    '/companyActivities',
    {
      params,
    },
  );
  return data;
};

/**
* * get One Company Activity (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/companyActivities/-companyActivityId-)
+*/

export interface GetCompanyActivityListParams {}

export const getOneCompanyActivity = async (
  companyActivityId: number | string,
) => {
  const { data } = await Axios.get<CompanyActivityData>(
    `/companyActivities/${companyActivityId}`,
  );
  return data;
};

/**
* * create One Company Activity (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/companyActivities)
+*/

export interface CreateOneCompanyActivityParams {
  title: string;
  images: any;
  content: string;
  companyEventStartDate: string;
  companyEventEndDate: string;
  companyEventStartTime: string;
  companyEventEndTime: string;
  isOnline: boolean;
  zoomRoomNumber: string | null;
  zoomRoomPassword: string | null;
  publishedDate: string;
}

export const createOneCompanyActivity = async (
  params: CreateOneCompanyActivityParams,
) => {
  const { data } = await Axios.post<CompanyActivityData>(
    '/companyActivities',
    params,
  );
  return data;
};

/**
* * Update One Company Activity (https://svgroup-app-dev.appicidea.com/doc/v2#patch-/v1/companyActivities/-companyActivityId-)
+*/

// export interface UpdateOneCompanyActivityParams
//   extends Filter<CompanyActivityData> {}

export const updateOneCompanyActivity = async (
  companyActivityId: number | string,
  params: Partial<CreateOneCompanyActivityParams>,
) => {
  const { data } = await Axios.patch<CompanyActivityData>(
    `/companyActivities/${companyActivityId}`,
    params,
  );
  return data;
};

/**
* * Delete One Company Activity (https://svgroup-app-dev.appicidea.com/doc/v2#delete-/v1/companyActivities/-companyActivityId-)
+*/

export const deleteOneCompanyActivity = async (
  companyActivityId: number | string,
) => {
  const { data } = await Axios.delete<string>(
    `/companyActivities/${companyActivityId}`,
  );
  return data;
};
