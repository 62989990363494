import React from 'react';

interface Props {
  children: React.ReactChild;
}
function NonAuthLayout(props: Props) {
  return <>{props.children}</>;
}

export default NonAuthLayout;
