import React, { ImgHTMLAttributes, useState } from 'react';

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  alt?: string;
  src: string;
  fallbackImgSrc?: string;
  fallbackImageComponent?: React.ReactElement;
}

const Image = (props: Props) => {
  const { alt, src, fallbackImgSrc, fallbackImageComponent } = props;
  const [isError, setError] = useState<boolean>(false);

  const onError = () => {
    setError(true);
  };
  if (isError && fallbackImageComponent) {
    return fallbackImageComponent;
  }
  return (
    <img
      {...props}
      alt={alt}
      src={isError ? fallbackImgSrc : src}
      onError={onError}
    />
  );
};

export default Image;
