import clsx from 'clsx';
import { FieldHookConfig, useField } from 'formik';
import React, { CSSProperties } from 'react';
import { Input, Label } from 'reactstrap';

import './input.scss';

type InputType =
  | 'textarea'
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

interface OtherProps {
  name: string;
  label: string;
  labelStyle?: CSSProperties;
  horizontal?: boolean;
  type?: InputType;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  resError?: any;
}

const InputField = (props: OtherProps & FieldHookConfig<string>) => {
  const [field, meta, helpers] = useField(props);
  const { label, type, placeholder, disabled, onChange, resError, labelStyle } =
    props;
  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
      )}
      style={{ height: 85 }}
    >
      {label ? <Label style={labelStyle}>{props.label}</Label> : null}
      <Input
        className="form-control"
        {...field}
        placeholder={placeholder}
        type={type}
        autoComplete="new-password"
        disabled={disabled}
        value={field.value || ''}
        style={type === 'textarea' ? { minHeight: 85 } : { height: 38 }}
        onChange={e => {
          if (onChange) {
            onChange(e);
          } else {
            helpers.setValue(e.target.value);
          }
        }}
      />
      {(meta.touched && meta.error) || resError ? (
        <div className="text-danger">{resError ? resError : meta.error}</div>
      ) : null}
    </div>
  );
};

export default InputField;
