import React from 'react';

const Breadcrumb = ({
  count,
  title,
  size,
  fontWeight,
}: {
  count: number;
  title: string;
  size?: string;
  fontWeight?: string;
}) => {
  return (
    <div className="d-flex flex-row align-items-center justify-content-center">
      <div
        className="text-dark font-weight-bold"
        style={{ fontWeight: 700, fontSize: size ? size : '' }}
      >
        {title}
      </div>
      <div
        className="font-size-24 text-muted ms-2"
        style={{ fontWeight: fontWeight ? fontWeight : '' }}
      >
        {count}
      </div>
    </div>
  );
};

export default Breadcrumb;
