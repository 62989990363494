import { customImportErrorHelper } from 'app/helpers/errorHelper';
import { useAutoComplete } from 'app/hooks';
import { UserTagItem } from 'app/models';
import {
  GetUserTagListParams,
  getUserTagList,
  importUserToOneTags,
} from 'app/services/UserService';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Icons, toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';

import { AutoCompleteField, ExcelField } from '../Form';
import './UploadUserTagTemplateForm.scss';

interface Props {
  setDialogVisible: (value: boolean) => void;
}

export type FormItem = {
  tagName: string;
  uploadFile: FormData | null;
};

// form initialValues
const FORM_ITEM = {
  tagName: '',
  uploadFile: null,
};

const SCHEMA = Yup.object({
  tagName: Yup.string().required('必填項目'),
  uploadFile: Yup.mixed().required('必填項目'),
});

const UploadUserTagTemplateForm = ({ setDialogVisible }: Props) => {
  const [form] = useState<FormItem>(FORM_ITEM);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    searchText: tagText,
    setSearchText: setTagText,
    dataList: tagList,
    onMenuScrollToBottom: onTagListScrollToBottom,
  } = useAutoComplete<GetUserTagListParams, UserTagItem>({
    getListCall: getUserTagList,
    labelRender: (item: UserTagItem) => ({
      label: `${item.tagName}`,
      value: item.tagName,
    }),
    keyExtracter: (item: UserTagItem) => item.tagName,

    searchTextFieldName: 'tagName',
    asc: 'tagName',
  });

  const onSubmit = async (values: FormItem) => {
    setLoading(true);
    const toastLoading = toast.loading('文件上載中', {
      icon: Icons.spinner,
    });

    const { tagName, uploadFile } = values;
    try {
      if (uploadFile) {
        const formData = new FormData();
        formData.append('file', uploadFile[0]);
        formData.append('tagName', tagName);
        const res = await importUserToOneTags(formData);
        customImportErrorHelper(toastLoading, res);
        setDialogVisible(false);
      }
    } catch (err) {
      console.log(err);
      toast.update(toastLoading, { render: '未能上載至伺服器，請重試。' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-2">
      <Formik
        initialValues={form}
        validationSchema={SCHEMA}
        onSubmit={onSubmit}
      >
        {({ values, errors, setFieldValue }) => (
          <Form className="form-horizontal">
            <Row>
              <Col md={12}>
                <AutoCompleteField
                  name="tagName"
                  placeholder="請選擇"
                  label="會員Tag"
                  options={tagList}
                  inputValue={tagText}
                  onInputChange={setTagText}
                  onMenuScrollToBottom={onTagListScrollToBottom}
                  height={250}
                />
              </Col>
              <Col md={12}>
                <ExcelField name="uploadFile" />
              </Col>
            </Row>
            <div className="mt-3 d-flex flex-row-reverse">
              <Button
                type="submit"
                color="primary"
                className="ms-1"
                disabled={loading}
              >
                {loading ? (
                  <i className="bx bx-loader-circle bx-spin" />
                ) : (
                  '提交'
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UploadUserTagTemplateForm;
