import { Filter, ListResult } from 'app/models';
import {
  AttendeesInCourseApplicationData,
  CourseApplicationData,
  CourseApplicationStatus,
  CourseAttendees,
  CourseData,
  CoursePaymentOptions,
  CoursePaymentStatus,
  CourseSchedules,
  CourseStatus,
  CourseUnAttendRecord,
  courseAttachmentDriveType,
  courseAttachmentFolderType,
  courseMaterialDriveType,
  courseMaterialFolderType,
} from 'app/models/Course';

import Axios from '.';

/**
 * Get all Course list (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/courses)
 */

export interface GetCourseListParams extends Filter<CourseData> {
  courseName?: string;
  isOnline?: boolean;
  isFull?: boolean;
  courseStatus?: CourseStatus;
  startDate?: string;
  endDate?: string;
}

export const getCourseList = async (params: GetCourseListParams) => {
  const { data } = await Axios.get<ListResult<CourseData>>('/courses', {
    params,
  });
  return data;
};

/**
 * get One Course (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/courses/-courseId-)
 */

export const getOneCourse = async (courseId: string | number) => {
  const { data } = await Axios.get<CourseData>(`/courses/${courseId}`);
  return data;
};

/**
 * get All Course Schedule List (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/courses/schedules)
 */

export interface GetCourseScheduleListParams extends Filter<CourseSchedules> {
  startDate?: string;
  endDate?: string;
}

export const getCourseScheduleList = async (
  params: GetCourseScheduleListParams,
) => {
  const { data } = await Axios.get<ListResult<CourseSchedules>>(
    '/courses/schedules',
    {
      params,
    },
  );
  return data;
};

/**
 * get All Course Application List (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/courses/applications)
 */

export interface GetAllCourseApplicationListParams
  extends Filter<CourseApplicationData> {
  status?:
    | 'reserved'
    | 'waitingList'
    | 'pendingReview'
    | 'completed'
    | 'canceled';
  courseId?: number;
  userId?: number;
  paymentStatus?: CoursePaymentStatus;
}

export const getAllCourseApplicationList = async (
  params: GetAllCourseApplicationListParams,
) => {
  const { data } = await Axios.get<ListResult<CourseApplicationData>>(
    '/courses/applications',
    {
      params,
    },
  );
  return data;
};

/**
 * get One Course Application List (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/courses/-courseId-/applications)
 */

export interface GetOneCourseApplicationListParams
  extends Filter<CourseApplicationData> {
  status?:
    | 'reserved'
    | 'waitingList'
    | 'pendingReview'
    | 'completed'
    | 'canceled';
  paymentStatus?: CoursePaymentStatus;
}

export const getOneCourseApplicationList = async (
  courseId: string | number,
  params: GetOneCourseApplicationListParams,
) => {
  const { data } = await Axios.get<ListResult<CourseApplicationData>>(
    `/courses/${courseId}/applications`,
    { params },
  );
  return data;
};

/**
 * get All Course Attendees in Course Application List (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/courses/-courseId-/applications)
 */

type StatusFilter = 'reserved' | 'pendingReview' | 'completed' | 'canceled';

export interface GetAllAttendeesInCourseApplicationParams
  extends Filter<CourseAttendees> {
  status?: StatusFilter[];
  paymentStatus: CoursePaymentStatus;
  name?: string;
}

export const getAllAttendeesInCourseApplication = async (
  courseId: string | number,
  params: GetAllAttendeesInCourseApplicationParams,
) => {
  const { data } = await Axios.get<ListResult<CourseAttendees>>(
    `/courses/${courseId}/attendees`,
    { params },
  );
  return data;
};

/**
 * get Course Attendee Record (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/courses/-courseId-/attendeeRecord)
 */

export interface GetAllAttendeesRecordParams
  extends Filter<CourseApplicationData> {
  courseScheduleId?: number;
  courseApplicationId?: number;
  preAgentId?: number;
  userId?: number;
}

export const getAllCourseAttendeesRecord = async (
  courseId: string | number,
  params: GetAllAttendeesRecordParams,
) => {
  const { data } = await Axios.get<
    ListResult<AttendeesInCourseApplicationData>
  >(`/courses/${courseId}/attendeeRecord`, { params });
  return data;
};

/**
 * get Course UnAttendee Record List (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/courses/-courseId-/unAttendeeRecord)
 */

export interface GetAllUnAttendeesRecordParams
  extends Filter<CourseApplicationData> {
  courseScheduleId?: number;
  courseApplicationId?: number;
  preAgentId?: number;
  userId?: number;
}

export const getAllUnCourseAttendeesRecord = async (
  courseId: string | number,
  params: GetAllAttendeesRecordParams,
) => {
  const { data } = await Axios.get<ListResult<CourseUnAttendRecord>>(
    `/courses/${courseId}/unAttendeeRecord`,
    { params },
  );
  return data;
};

/**
 * get One Course Attendee Record (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/courses/-courseId-/attendeeRecord/-courseAttendanceRecordId-)
 */

export const getOneCourseAttendeesRecord = async (
  courseId: number,
  courseAttendanceRecordId: number,
) => {
  const { data } = await Axios.get<AttendeesInCourseApplicationData>(
    `/courses/${courseId}/attendeeRecord/${courseAttendanceRecordId}`,
  );
  return data;
};

/**
 * Create One Course (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/courses)
 */

interface courseSchedulesArr {
  date: string;
  startTime: string;
  endTime: string;
  zoomRoomNumber: string;
  zoomRoomPassword: string;
  surveyId: number | null;

  materialFolderType: courseMaterialFolderType | null;
  materialDriveType: courseMaterialDriveType | null;
  materialPath: string;

  pointEarnAfterCheckIn: number | null;
}

export interface CreateOneCourseParams {
  courseCode: string;
  courseTitle: string;
  startDate: string;
  endDate: string;
  registrationDeadline: string;
  courseDescription: string | null;
  images: any;
  courseLocation: string | null;
  courseQuota: number;
  contactPhoneNumber: string | null;
  contactPerson: string | null;
  // courseUrl: string | null;
  isOnline: boolean;
  allowGuest: boolean;
  CourseSchedules: courseSchedulesArr[];
  courseAttachmentFolderType: courseAttachmentFolderType | null;
  courseAttachmentDriveType: courseAttachmentDriveType | null;
  courseAttachmentPath: string;

  materialFolderType: courseMaterialFolderType | null;
  materialDriveType: courseMaterialDriveType | null;
  materialPath: string;

  pointEarnAfterApply: number | null;

  courseReminder: string | null;
  paymentOptions: CoursePaymentOptions[];
  publishedDate: string;
  permissionTags: string[];
  permissionRankCodes: string[];
  surveyId: number | null;
}

export const createOneCourse = async (params: CreateOneCourseParams) => {
  const { data } = await Axios.post<CourseData>('/courses', params);
  return data;
};

/**
 * Create One Attendee Record (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/courses/-courseId-/attendeeRecord)
 */

export interface CreateOneCourseRecordParams {
  courseAttendeeId: number;
  courseScheduleId: number;
  checkInDateTime: string | string[] | null;
  checkOutDateTime: string | string[] | null;
}

export const createOneCourseRecord = async (
  courseId: number,
  params: CreateOneCourseRecordParams,
) => {
  const { data } = await Axios.post<CourseAttendees>(
    `/courses/${courseId}/attendeeRecord`,
    params,
  );
  return data;
};

/**
 * Update One Course (https://svgroup-app-dev.appicidea.com/doc/v2#patch-/v1/courses/-courseId-)
 */

export interface UpdateOneCourseParams {}

export const updateOneCourse = async (
  courseId: number | string,
  params: UpdateOneCourseParams,
) => {
  const { data } = await Axios.patch<CourseData>(
    `/courses/${courseId}`,
    params,
  );
  return data;
};

/**
 * Update One Course Application (https://svgroup-app-dev.appicidea.com/doc/v2#patch-/v1/courses/application/-courseApplicationId-)
 */

export interface UpdateOneCourseApplicationParams {
  status: CourseApplicationStatus;
}

export const updateOneCourseApplication = async (
  courseApplicationId: number | string,
  params: UpdateOneCourseApplicationParams,
) => {
  const { data } = await Axios.patch<CourseApplicationData>(
    `/courses/application/${courseApplicationId}`,
    params,
  );
  return data;
};

/**
 * Update One Course AttendeeRecord (https://svgroup-app-dev.appicidea.com/doc/v2#patch-/v1/courses/-courseId-/attendeeRecord/-courseAttendanceRecordId-)
 */

export interface UpdateOneCourseAttendeeRecordParams {
  checkInDateTime: string | null;
  checkOutDateTime: string | null;
}

export const updateOneCourseAttendeeRecord = async (
  courseId: number | string,
  courseAttendanceRecordId: number | string,
  params: UpdateOneCourseAttendeeRecordParams,
) => {
  const { data } = await Axios.patch<UpdateOneCourseAttendeeRecordParams>(
    `/courses/${courseId}/attendeeRecord/${courseAttendanceRecordId}`,
    params,
  );
  return data;
};

/**
 * Delete One Course (https://svgroup-app-dev.appicidea.com/doc/v2#delete-/v1/courses/-courseId-)
 */

export const deleteOneCourse = async (courseId: number | string) => {
  const { data } = await Axios.delete<string>(`/courses/${courseId}`);
  return data;
};

/**
 * Create one coursee application (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/courses/-courseId-/applications)
 */

export interface CreateOneCourseApplicationParams {
  userId: number;
  paymentOption: {
    cash: number;
    point: number;
  };
  Attendees?: {
    userId?: number | null;
    preAgentId?: number | null;
  }[];
}

export const createOneCourseApplication = async (
  courseId,
  params: CreateOneCourseApplicationParams,
) => {
  const { data } = await Axios.post<any>(
    `/courses/${courseId}/applications`,
    params,
  );
  return data;
};

/**
 * Export course Attendee List Excel (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/courses/-courseId-/attendee.xlsx)
 */

export const exportCourseAttendeeListExcel = async (courseId: number) => {
  const { data } = await Axios.get<null>(`/courses/${courseId}/attendee.xlsx`);
  return data;
};

/**
 * Export course Application List Excel (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/courses/-courseId-/application.xlsx)
 */

export const exportCourseApplicationListExcel = async (courseId: number) => {
  const { data } = await Axios.get<null>(
    `/courses/${courseId}/application.xlsx`,
  );
  return data;
};

/**
 * Import course Application List Excel (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/courses/-courseId-/applications/import)
 */

export const importCourseApplicationListExcel = async (
  courseId: number,
  params: FormData,
) => {
  const { data } = await Axios.post<any>(
    `/courses/${courseId}/applications/import`,
    params,
  );
  return data;
};
