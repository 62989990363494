export const COURSE_ISONLINE_OPTIONS = [
  { label: '所有', value: '' },
  { label: '實體課程', value: 'false' },
  { label: '網上課堂', value: 'true' },
];

export const COURSE_ISFULL_OPTIONS = [
  { label: '所有', value: '' },
  { label: '仍可報名', value: 'false' },
  { label: '額滿', value: 'true' },
];

export const COURSE_STATUS_OPTIONS = [
  { label: '所有', value: '' },
  { label: '未開始', value: '未開始' },
  { label: '進行中', value: '進行中' },
  { label: '己結束', value: '己結束' },
];

export const COURSE_APPLICATION_STATUS_OPTIONS = [
  { label: '所有', value: '' },
  { label: '已報名', value: 'reserved' },
  { label: '已取消', value: 'canceled' },
];
