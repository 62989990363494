/* eslint-disable jsx-a11y/role-supports-aria-props */
// MetisMenu
import MetisMenu from 'metismenujs';
import React, { useCallback, useEffect, useRef } from 'react';
//i18n
// import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
// //Import Scrollbar
import SimpleBar from 'simplebar-react';

const SidebarContent = props => {
  // const { t } = useTranslation();
  const ref = useRef<any>();
  const location = useLocation();

  const activateParentDropdown = useCallback(item => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = location.pathname;
    const initMenu = () => {
      new MetisMenu('#side-menu');
      let matchingMenuItem: any = null;
      const ul = document.getElementById('side-menu');
      const items = ul?.getElementsByTagName('a');
      if (items) {
        for (let i = 0; i < items.length; ++i) {
          if (pathName === items[i].pathname) {
            matchingMenuItem = items[i];
            break;
          }
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [location.pathname, activateParentDropdown]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.recalculate();
    }
  });

  const scrollElement = item => {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        if (ref && ref.current) {
          ref.current.getScrollElement().scrollTop = currentPosition - 300;
        }
      }
    }
  };

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: '100%', minHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">資訊發佈</li>
            <li>
              <NavLink
                to="/courses"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="mdi mdi-certificate"></i>
                <span>課堂</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/events"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="mdi mdi-calendar-month-outline"></i>
                <span>活動及會議</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/company"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="mdi mdi-calendar-account"></i>
                <span>公司活動</span>
              </NavLink>
            </li>
            <li>
              <NavLink className="has-arrow waves-effect" to="/banners">
                <i className="mdi mdi-view-carousel-outline"></i>
                <span>橫額</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="/banners" activeClassName="active mm-active">
                    橫額
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/banners-sort"
                    activeClassName="active mm-active"
                  >
                    橫額排序
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                to="/popup-banner"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="mdi mdi-party-popper"></i>
                <span>彈出海報</span>
              </NavLink>
            </li>
            <li>
              <NavLink className="has-arrow waves-effect" to="/news">
                <i className="mdi mdi-newspaper"></i>
                <span>新聞</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="/news" activeClassName="active mm-active">
                    新聞
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/news-sort" activeClassName="active mm-active">
                    新聞排序
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                to="/notifications"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-bell"></i>
                <span>推送訊息</span>
              </NavLink>
            </li>

            <li className="menu-title">會員管理</li>
            <li>
              <NavLink className="has-arrow waves-effect" to="/users">
                <i className="bx bx-user"></i>
                <span>會員</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="/users" activeClassName="active mm-active">
                    會員列表
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/user-tags" activeClassName="active mm-active">
                    會員Tags
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                to="/statements"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="fas fa-money-check-alt"></i>
                <span>會員帳單</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/transactions"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="mdi mdi-currency-usd"></i>
                <span>會員交易紀錄</span>
              </NavLink>
            </li>

            <li>
              <NavLink className="has-arrow waves-effect" to="/performances">
                <i className="mdi mdi-trophy-outline"></i>
                <span>龍虎榜</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink
                    to="/performances"
                    activeClassName="active mm-active"
                  >
                    會員表現
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/ranking" activeClassName="active mm-active">
                    會員龍虎榜
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="menu-title">其他</li>

            <li>
              <NavLink
                to="/drive"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="mdi mdi-folder-multiple-outline"></i>
                <span>資料庫</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/admins"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-user-circle"></i>
                <span>管理員</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/surveys"
                className="has-arrow waves-effect"
                activeClassName="active mm-active"
              >
                <i className="fab fa-wpforms"></i>
                <span>資源及評價</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink
                    to="/surveys-response"
                    activeClassName="active mm-active"
                  >
                    評價反饋列表
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/surveys" activeClassName="active mm-active">
                    新增資源及評價
                  </NavLink>
                </li>
              </ul>
            </li>

            <li>
              <NavLink className="has-arrow waves-effect" to="/contacts">
                <i className="bx bxs-contact"></i>
                <span>聯絡資料</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="/contacts" activeClassName="active mm-active">
                    聯絡資料
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/contact-category-sort"
                    activeClassName="active mm-active"
                  >
                    聯絡資料排序
                  </NavLink>
                </li>
              </ul>
            </li>

            <li>
              <NavLink className="has-arrow waves-effect" to="/infos">
                <i className="mdi mdi-information-outline"></i>
                <span>業界資訊</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="/infos" activeClassName="active mm-active">
                    業界資訊
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/infos-sort" activeClassName="active mm-active">
                    業界資訊排序
                  </NavLink>
                </li>
              </ul>
            </li>

            <li>
              <NavLink
                to="/maintenances"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="mdi mdi-progress-wrench"></i>
                <span>系統維護</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SidebarContent;
