import { OptionItems } from 'app/models';
import clsx from 'clsx';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { Label } from 'reactstrap';

import './autoCompleteSelect.scss';

interface Props {
  label?: string;
  placeholder?: string;
  options: OptionItems[];
  onChange?: (value: OptionItems) => void;
  onMenuScrollToBottom?: () => void;
  onMenuClose?: () => void;
  onInputChange: (e) => void;
  disabled?: boolean;
  menuPlacement?: 'top' | 'bottom' | 'auto';
  name: string;
  isClearable?: boolean;
  isInModal?: boolean;
  customValue?: OptionItems | OptionItems[] | null;
  loading?: boolean;
}

const AutoCompleteSelect = ({
  label,
  placeholder,
  options,
  onChange,
  onMenuScrollToBottom,
  onMenuClose,
  onInputChange,
  disabled,
  menuPlacement,
  name,
  isClearable,
  isInModal = false,
  customValue,
  loading,
}: Props) => {
  const location = useLocation();
  const history = useHistory();
  const [value, setValue] = useState<OptionItems | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const key = query.get(name);
    const keyLabel = query.get(`${name}Label`);
    if (key && keyLabel) {
      setValue({
        value: key,
        label: keyLabel,
      });
    } else {
      setValue(null);
    }
  }, [location.search, name, options]);

  const searchOnChange = text => {
    setInputValue(text);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(_.debounce(onInputChange, 500), []);

  useEffect(() => {
    debounceSearch(inputValue);
  }, [inputValue, debounceSearch]);

  return (
    <div className={clsx('mb-2', 'form-group')}>
      {label ? <Label>{label}</Label> : null}
      <div className="w-100 position-relative">
        <Select
          defaultValue={value}
          isMulti={false}
          placeholder={placeholder}
          options={options}
          classNamePrefix="select2-selection"
          value={customValue || value}
          onChange={e => {
            if (onChange) {
              onChange(e);
            } else {
              const params = new URLSearchParams(location.search);
              if (e && e.value) {
                params.set(name, e.value);
                params.set(`${name}Label`, e.label);
              } else {
                params.delete(name);
                params.delete(`${name}Label`);
              }
              params.set('page', '1');
              history.push({ search: params.toString() });
            }
          }}
          onMenuScrollToBottom={onMenuScrollToBottom}
          onMenuClose={onMenuClose}
          captureMenuScroll={true}
          onInputChange={searchOnChange}
          inputValue={inputValue}
          isDisabled={disabled || loading}
          menuPlacement={menuPlacement || 'auto'}
          noOptionsMessage={() => '無選項'}
          isClearable={isClearable}
          menuPortalTarget={!isInModal ? document.body : undefined}
          styles={{
            menuPortal: base => ({
              ...base,
              zIndex: 9999,
            }),
          }}
        />
        {loading ? (
          <div className="selec-filter-loading">
            <i className="bx bx-loader-circle bx-spin text-primary" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AutoCompleteSelect;
