import {
  DriveObject,
  DriveType,
  FileType,
  FileTypeResponse,
  GetOneFileTypeRes,
  ShareDriveTokenResponse,
} from 'app/models';

import Axios from '.';

/**
 * Get folder list (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/drive)
 */

export interface GetFolderListParams {
  folderType: FileType;
  driveType: DriveType;
  path: string;
}

export const getFolderList = async (params: GetFolderListParams) => {
  const { data } = await Axios.get<DriveObject[]>('/drive', {
    params,
  });
  return data;
};

/**
 * Get one file (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/drive/file)
 */

export const getOneDriveFile = async (params: GetFolderListParams) => {
  const { data } = await Axios.get<Blob>('/drive/file', {
    params,
  });
  return data;
};

/**
 * delete one file (https://svgroup-app-dev.appicidea.com/doc/v2#delete-/v1/drive/file)
 */

export const deleteOneDriveFile = async (params: GetFolderListParams) => {
  const { data } = await Axios.delete<any>('/drive/file', { params });
  return data;
};

/**
 * upload one file to drive (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/drive/upload)
 */

export const uploadOneFileToDrive = async (
  params: GetFolderListParams,
  file: FormData,
) => {
  const { data } = await Axios.post<any>('/drive/upload', file, {
    params,
    timeout: 1000 * 60 * 50,
  });
  return data;
};

/**
 * Move/ Rename File/Folder folder (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/drive/rename)
 */

export const mvFileToDrive = async (params: {
  source: GetFolderListParams;
  dest: GetFolderListParams;
}) => {
  const { data } = await Axios.post<any>('/drive/rename', params);
  return data;
};

/**
 * copy File/Folder folder (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/drive/copy)
 */

export const copyFileToDrive = async (params: {
  source: GetFolderListParams;
  dest: GetFolderListParams;
}) => {
  const { data } = await Axios.post<any>('/drive/copy', params);
  return data;
};

/**
 * create folder (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/drive/createFolder)
 */

export const createFolderInDrive = async (params: GetFolderListParams) => {
  const { data } = await Axios.post<any>(
    '/drive/createFolder',
    {},
    {
      params,
    },
  );
  return data;
};

/**
 * search in folder (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/drive/search)
 */

export interface SearchFileInDriveParams {
  folderType?: FileType;
  driveType: DriveType;
  path?: string;
  q: string;
}

export const searchFileInDrive = async (params: SearchFileInDriveParams) => {
  const { data } = await Axios.get<DriveObject[]>('/drive/search', { params });
  return data;
};

/**
 * get One file in drive (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/drive/fileType)
 */

export const getOneFileType = async (params: GetFolderListParams) => {
  const { data } = await Axios.get<FileTypeResponse>('/drive/fileType', {
    params,
  });
  return data;
};

export interface getOneFileTypeParams {
  folderType: FileType;
  driveType: DriveType;
  path: string;
}

export interface GetOneShareFileTypeParams extends getOneFileTypeParams {
  shareToken?: string;
}

export const getShareFolderToken = async (params: getOneFileTypeParams) => {
  const { data } = await Axios.post<ShareDriveTokenResponse>(
    '/drive/share',
    params,
  );
  return data;
};

export const getOneShareFileType = async (
  params: GetOneShareFileTypeParams,
) => {
  const { data } = await Axios.get<GetOneFileTypeRes>('/drive/share/fileType', {
    params,
  });
  return data;
};
