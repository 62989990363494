import { APP_NAME } from 'app/config';
import { Helmet } from 'react-helmet-async';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

export const PrivacyPolicyPage = () => {
  return (
    <div className="page-content">
      <Helmet>
        <title>SV私隱政策 | {APP_NAME}</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <div className="pt-3">
                  <div className="row justify-content-center">
                    <div className="col-xl-8">
                      <div>
                        <div className="text-center">
                          <h4>私隱政策</h4>
                        </div>

                        <hr />

                        <div className="mt-4">
                          <div className="text-muted font-size-14">
                            <h5 className="mb-3">1. 一般</h5>

                            <p className="mb-4">
                              1.1
                              宏星管理咨詢公司及其附屬/關聯網站(以下總稱為「我們」，各自為「集團
                              成員」)非常重視閣下個人私隱的法律權利,承諾會依照個人資料(私隱)條例(香
                              港特別行政區(「香港」)法例第 486
                              章)(「條例」)及其他適用的私隱法例的要求
                              保障閣下的「個人資料」(見下文定義)。我們非常重視閣下的個人資料，我們會
                              採取數個步驟以確保我們在提供服務時不會收集比我們所需的更多的個人資
                              料，亦確保閣下的個人資料在我們保存的期間經常保存在安全的地方。
                            </p>

                            <p className="mb-4">
                              1.2
                              此私隱政策說明了我們的私隱慣例。請小心閱讀以理解我們會如何依照條
                              例的要求處理閣下的個人資料。透過連接我們的手機應用程式及/或網站
                              (
                              <a href="https://www.svgroup.hk/">
                                www.svgroup.hk
                              </a>
                              )或其任何網頁(以下總稱為「本網上平台」)
                              ，及向我們提供閣
                              下的個人資料，表示閣下同意本使用條款，並同意及接受本使用條款之約束。
                              但是，閣下明白閣下如未能提供部分所需的個人資料，閣下將不能連接或使用
                              本網上平台的部份內容，以及本網上平台的部分功能或未能正常運作或提供給
                              閣下。
                            </p>

                            <p className="mb-4">
                              1.3
                              此私隱政策或不時有所修訂，相關修訂將刊登於本網上平台。我們將不會
                              為此向閣下發送個別通知，並鼓勵閣下持續留意刊登於本網上平台的變更。
                            </p>

                            <h5 className="mb-3">2. 我們所收集個人資料</h5>

                            <p className="mb-4">
                              2.1
                              我們或不時收集我們在香港向其提供服務或產品，或與我們建立或維持商
                              業或客戶關聯的人士(「相關人士」或「閣下」視乎屬何情況而定)的個人資料。
                              在本私隱政策中，「個人資料」的定義與條例中的意思相同。
                            </p>

                            <p className="mb-4">
                              2.2
                              本私隱政策適用於所有從相關人士收集或遞交給我們(不論是經本網上平台
                              或其他途徑)的個人資料。我們會在邀請閣下提供個人資料時列明收集個人資料
                              的目的及預期用途。閣下如向我們提供個人資料，即代表閣下已同意我們可使
                              用該個人資料作以下用途:
                            </p>

                            <ol type="a">
                              <li>處理閣下的訂單及處理付款;</li>
                              <li>處理閣下的要求及查詢;</li>
                              <li>改善及提升閣下在本網上平台的經驗;</li>
                              <li>自行設定閣下的個人檔案;</li>
                              <li>評估及進一步發展本網上平台及/或其內容;</li>
                              <li>確認閣下的身份;</li>
                              <li>通知閣下換領禮品或優惠卷，及其相關安排;</li>
                              <li>
                                作直銷活動，例如向閣下提供有關我們當期產品、服務、宣傳活動、或其
                                他推廣資料或資訊(如有) ;
                              </li>
                              <li>作研究及統計分析;及</li>
                              <li>
                                履行適用於我們或我們應遵從的義務、要求或安排披露及使用個人資料，如下:
                                <div>
                                  任何我們應遵從的法律、監管、政府、稅務、執法或其他機關，或行業機構或
                                  協會發布的任何法律或指令的要求(包括當前和未來存在的);及/或
                                  任何對我們具有約束力和適用的法律，無論是目前或將來存在的或是在香港境
                                  內還是境外的;及
                                </div>
                              </li>
                              <li>收集資料時說明的其他目的。</li>
                            </ol>
                            <p className="mb-4">
                              2.3
                              如我們改變使用閣下個人資料的目的，我們會事前透過電郵取得閣下的書
                              面同意。
                            </p>
                            <p className="mb-4">
                              2.4 我們會以以下兩種方式收集個人資料:
                            </p>

                            <p className="mb-4">
                              由閣下提供的個人資料 –
                              當閣下在本網上平台註冊帳戶及其後登入閣下的帳
                              戶時;當閣下在本網上平台進行交易時;及當閣下要求得到一些資訊及/或作出
                              提問時，閣下將會被要求提供個人資料，例如姓名及電郵地址。
                              透過使用本網上平台收集 –
                              我們可收集關於閣下使用本網上平台的資料及閣
                              下使用的方法。這類資料包括但不限於:
                            </p>
                            <div>
                              <strong>裝置資料</strong>
                            </div>
                            <p className="mb-4">
                              我們可收集閣下裝置的專屬資料(例如硬件型號及運作系統版本)。
                            </p>
                            <div>
                              <strong>記錄資料</strong>
                            </div>
                            <p className="mb-4">
                              當閣下瀏覽本網上平台的內容時，我們可收集及儲存資料於伺服器記錄內。這類資料包括:
                            </p>
                            <p className="mb-4">
                              閣下對本網上平台的瀏覽詳情，例如搜尋查詢;
                            </p>
                            <p className="mb-4">網際協定位址(IP 位置);</p>
                            <p className="mb-4">
                              裝置活動資料，例如當機數據、系統活動、硬件設定、瀏覽器類型及語言、您
                              提出要求的日期和時間，及/或參照連結網址;及
                            </p>
                            <p className="mb-4">
                              用於識別您閣下的瀏覽器或使用者賬戶的專屬
                              Cookies(「Cookies」)。
                            </p>

                            <div>
                              <strong>位置信息</strong>
                            </div>
                            <p className="mb-4">
                              當閣下使用本網上平台，我們可收集及分析關於閣下的位置如當時身處的國家
                              及城市。
                            </p>

                            <div>
                              <strong>Cookies 及匿名識別碼</strong>
                            </div>
                            <p className="mb-4">
                              Cookies
                              是當閣下瀏覽一個網站時傳輸給閣下的設備的一種小型的檔案，用以記
                              錄使用者的偏好及資料。它讓該網站在往後可確認閣下的瀏覽器以及經常被用
                              於追蹤閣下對本網上平台提供的主題上的偏好。
                            </p>

                            <p className="mb-4">
                              閣下瀏覽及使用本網上平台時可能會觸發本網上平台使用
                              Cookies 及/或匿名識
                              別碼以加強保安、為閣下個人化本網上平台、在閣下的註冊帳戶建立個人檔案
                              及讓閣下瀏覽本網上平台及基於閣下的選擇讓閣下可使用本網上平台的某些功
                              能。
                            </p>
                            <p className="mb-4">
                              閣下可重設閣下的瀏覽器以停止 Cookies
                              傳送。不過，這可能會導致閣下不能
                              使用或登入本網上平台的部分內容，及本網上平台的部分功能會不能正常運作
                              或暫停向閣下提供。
                            </p>
                            <p className="mb-4">
                              2.5
                              閣下向我們提供個人資料是屬自願性質的。但是，閣下如未能提供部分所
                              需的個人資料，我們可能無法向閣下提供我們的產品或服務或是將您視為相關
                              人士處理閣下的要求。
                            </p>

                            <h5 className="mb-3">3. 個人資料的分享及轉移</h5>
                            <p className="mb-4">
                              3.1
                              我們將採取一切合理可行的措施對您的個人資料進行保密，但我們可能會
                              出於上述任何目的向以下任何一方(無論是在香港境內還是境外)提供此類信
                              息:
                            </p>
                            <p className="mb-4">
                              向我們提供與我們的業務相關的營銷、促銷、行政、管理、電信、電腦、科
                              技、法律、支付清算或其他服務的任何代理、承包商或第三方服務提供商(包括
                              但不限於不定期推出的促銷、禮品、幸運抽獎、遊戲等活動)
                              ;
                              對我們負有保密義務的任何人士，包括但不限於承諾對此類信息保密的集團成
                              員;
                            </p>
                            <p className="mb-4">
                              根據對我們具有約束力或適用於我們的法律的要求，或根據任何我們該遵行的
                              法律、監管、政府、稅務、執法或其他機關或行業機構或協會發布的任何法律
                              或指令的要求(包括當前和未來存在的)，而使我們向其作出披露的任何人士;
                              任何我們實際或建議的受讓人，或
                              我們對相關人士持有的權利的執行人; 任何集團成員;
                            </p>
                            <p className="mb-4">
                              任何其他我們為上述目的而聘請的外部服務提供商(包括但不限於電話促銷公
                              司、數據處理公司和資訊科技公司)。
                            </p>
                            <p className="mb-4">
                              3.2
                              相關人士的個人資料可能會被轉移到香港以外的地方。在法律允許的範圍
                              內，我們將尋求確保任何跨司法管轄區的個人資料傳輸將獲得與香港所提供的
                              程度相似的保護。
                            </p>
                            <h5 className="mb-3">4. 直接促銷</h5>
                            <p className="mb-4">
                              4.1
                              我們可能會使用閣下的個人資料作直接促銷，但我們會在收集及/或使用閣
                              下的個人資料前先取到閣下對該直接促銷用途的同意或不反對表示。在這方面，請注意:
                            </p>
                            <p className="mb-4">
                              我們可不時使用閣下在我們記錄內的聯絡詳情如姓名、電話號碼及電郵地址以
                              進行直接促銷活動。這包括我們的餐廳、餐飲項目、及其他相關的活動、服務、及產品;及
                            </p>
                            <p className="mb-4">
                              上述促銷活動可能由我們及/或任何集團成員提供。
                            </p>
                            <p className="mb-4">
                              4.2 閣下可在任何時間依照以下第 7
                              條的聯絡詳情，通知我們負責處理個人資
                              料事務的資料保護主任，要求停止收取我們的直銷及宣傳資訊。
                            </p>

                            <h5 className="mb-3">5. 資訊保安</h5>
                            <p className="mb-4">
                              5.1
                              我們非常重視確保我們收集回來的個人資料的安全。我們已在物理上、電
                              子上及管理上安排了適當的程序以保護我們收集的資料。儘管實施了這些措
                              施，我們不能絕對肯定及保證在我們與閣下溝通的過程中或有關資料在本網上
                              平台或伺服器是永久及完全不被未有授權的第三方(如黑客)侵入。因此，當閣下
                              使用本網上平台，閣下已知悉閣下的個人資料有機會被第三方非法截取、存
                              取、收集及/或使用的可能性，並同意承擔此風險。儘管有這種可能性，我們仍
                              將尋求不斷改進和更新我們的網站、伺服器、及關連系統以進一步加強基礎資
                              訊安全。
                            </p>

                            <h5 className="mb-3">6. 準確性</h5>
                            <p className="mb-4">
                              6.1
                              為使我們能提供更好的服務給閣下，請確保閣下提供給我們的聯絡詳情(包
                              括閣下的地址、電話號碼、電郵地址、及其他相關資訊)是最新的。如閣下提供
                              的資料有任何修改或更新，請自行及時更新(如功能許可)，或依照以下第
                              7 條
                              的指示給予我們改正資料要求。我們不承擔因您提供給我們的資料不準確而導
                              致的任何和所有後果。
                            </p>
                            <h5 className="mb-3">
                              7. 個人資料的查閱、更正及停止使用
                            </h5>
                            <p className="mb-4">
                              7.1 根據並按照條例的條款，任何相關人士有權:
                            </p>
                            <p className="mb-4">
                              檢查我們是否持有有關他/她的個人資料，並要求查閱該等個人資料;
                              要求我們更正與他/她有關的任何不準確的個人資料;
                              確認我們有關個人資料的政策和慣例，並了解我們持有的個人資料的種類。
                            </p>
                            <p className="mb-4">
                              7.2
                              負責回應或處理有關查閱個人資料、行使選擇退出權利、更正個人資料、
                              或確認個人資料的政策和慣例及持有資料種類資訊等請求的人員的聯繫方式為
                              下:
                            </p>

                            <div className="mt-4 mb-4">
                              <p>資料保護主任</p>
                              <p>香港九龍觀塘道 348 號宏利廣場 23 樓</p>
                              <p>
                                電郵地址:{' '}
                                <a href="mailto:info@svgroup.hk">
                                  info@svgroup.hk
                                </a>
                              </p>
                            </div>

                            <p className="mb-4">
                              7.3 在收到足夠驗證閣下的身份的資訊後，我們將盡力在
                              40 天內滿足閣下的
                              要求。請注意根據條例的條款，我們有權可以在處理閣下的查閱資料要求時向
                              資料保護主任 香港九龍觀塘道 348 號宏利廣場 23 樓
                              電郵地址:info@svgroup.hk 您收取合理的費用。
                            </p>

                            <p className="mb-4">
                              7.4
                              為免生疑問，如果閣下撤回之前給予我們的同意，該撤回並不會影響我們
                              在閣下表示撤回同意前對閣下的個人資料進行的任何處理的合法性。
                            </p>

                            <h5 className="mb-3">8. 應用</h5>

                            <p className="mb-4">
                              8.1 本私隱政策適用於我們所有的手機應用程式及整個{' '}
                              <a href="https://www.svgroup.hk/">
                                www.svgroup.hk
                              </a>{' '}
                              網站及 其關聯網站。
                            </p>

                            <p className="mb-4">
                              8.2
                              本私隱政策不適用於有其獨立的個人資料保護政策的網站。本私隱政策也
                              不適用於其他公司或個人提供的服務，這包括閣下在搜尋結果可能出現的網
                              站、可能出現我們的內容的網站、或由本網上平台連結到的其他網站。本私隱
                              政策亦不適用於其他幫我們的服務賣廣告，及/或使用它們自己擁有的
                              Cookies、小型文字檔案或其他科技以提供相關廣告的公司及團體的資料處理政策。
                            </p>
                            <h5 className="mb-3">9. 執行</h5>
                            <p className="mb-4">
                              9.1
                              我們會定期檢討本私隱政策被貫徹執行的程度。當我們收到正式書面投訴
                              時，我們會連絡投訴人跟進。受限於任何我們必須遵守的法律及/或監管要求或
                              指示的情況下，我們會與合適的執法機構合作(包括當地的私隱保護組織)以解決
                              上述投訴。
                            </p>
                            <h5 className="mb-3">10. 準據語言</h5>
                            <p className="mb-4">
                              10.1
                              如本私隱政策的英文版本和其譯本有任何歧異，概以英文版本為準。
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
