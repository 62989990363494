import { User } from 'app/models';
import { Badge } from 'reactstrap';

const SelectUserListColumn = () => [
  {
    text: '會員姓名',
    dataField: 'displayName',
    canSort: true,
    formatter: (user: User) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">{user.displayName}</div>
        </h5>
      </>
    ),
  },
  {
    text: '職位',
    dataField: 'jobTitle',
    canSort: true,
    formatter: user => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {user.jobTitle ? user?.jobTitle : '-'}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '會籍等級',
    dataField: 'memberLevel',
    canSort: true,
    formatter: user => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {user?.memberLevel ? user?.memberLevel : '-'}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '會員種類',
    dataField: 'userType',
    canSort: true,
    formatter: user => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">{user?.userType}</div>
        </h5>
      </>
    ),
  },
  {
    text: '準成員編號',
    dataField: 'agentLicenseNumber',
    canSort: true,
    formatter: user => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {user?.agentLicenseNumber ? user?.agentLicenseNumber : '-'}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '狀態',
    dataField: 'status',
    canSort: true,
    formatter: (user: User) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {user?.status === '已啟用' || user?.status === null ? (
              <Badge
                pill
                color="none"
                className={`badge-soft-success ms-1 px-2 py-1 mt-1 font-size-12 rounded-pill`}
              >
                已啟用
              </Badge>
            ) : (
              <Badge
                pill
                className={`badge-soft-danger ms-1 px-2 py-1 mt-1 font-size-12 rounded-pill`}
                color="none"
              >
                已停用
              </Badge>
            )}
          </div>
        </h5>
      </>
    ),
  },
];

export default SelectUserListColumn;
