export const API_URL =
  process.env.REACT_APP_API_URL || 'https://svgroup-app-dev.appicidea.com';
export const IMAGE_URL =
  process.env.REACT_APP_IMAGE_URL || 'https://static.appicidea.com';
export const PAGE_SIZE = 20;
export const TOAST_TIME = 5000;
export const APP_NAME = '宏星集團';
export const COMPANY_NAME = 'SV CONSULTING GROUP LIMITED';
export const IMAGE_SIZE = 1024 * 10000;
export const PDF_SIZE = 1024 * 100000;
export const EXCEL_SIZE = 1024 * 10000;
export const SWAGGER_URL = 'https://appicidea.com';
export const PDF_VIEWER_URL =
  process.env.REACT_PDF_VIEWER_URL ||
  'https://static.appicidea.com/public/pdf/web/viewer.html';
export const WEB_APP_URL =
  process.env.REACT_APP_URL || 'https://svgroup-user-dev.appicidea.com';
