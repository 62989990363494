import zhtw_lang from 'flatpickr/dist/l10n/zh-tw.js';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
// import 'flatpickr/dist/themes/airbnb.css';
import 'flatpickr/dist/themes/material_blue.css';
import { BaseOptions } from 'flatpickr/dist/types/options';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { useHistory, useLocation } from 'react-router-dom';
import { InputGroup, Label } from 'reactstrap';

import './filter.scss';

interface Props {
  label?: string;
  horizontal?: boolean;
  placeholder?: string;
  onChange?: (e) => void;
  isClearable?: boolean;
  disabled?: boolean;
  menuPlacement?: 'top' | 'bottom' | 'auto';
  name: string;
  name2?: string;
  minDate?: Date | Date[] | null;
  clearable?: boolean;
  mode?: BaseOptions['mode'];
  monthPickr?: boolean;
}

const DatePickerFilter = ({
  label,
  placeholder,
  onChange,
  name,
  name2,
  minDate,
  mode,
  isClearable,
  monthPickr,
}: Props) => {
  const location = useLocation();
  const history = useHistory();
  const [value, setValue] = useState<Date | Date[] | null>(null);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const key = query.get(name);
    if (mode === 'range') {
      const key2 = query.get(name2!);
      if (key && key2) {
        setValue([moment(key).toDate(), moment(key2).toDate()]);
      } else {
        setValue(null);
      }
    } else {
      if (key) {
        setValue(moment(key).toDate());
      } else {
        setValue(null);
      }
    }
  }, [location.search, name, mode, name2]);

  const MonthSelectPlugin = new (monthSelectPlugin as any)({
    // shorthand: true,
    dateFormat: 'Y-m',
    altFormat: 'Y年m月',
    theme: 'light',
  });

  return (
    <div className="mb-2 form-group">
      {label ? <Label>{label}</Label> : null}
      <InputGroup style={{ position: 'relative' }}>
        <Flatpickr
          className="form-control d-block bg-white input-border date-picker-input"
          placeholder={placeholder || '日期'}
          options={{
            locale: zhtw_lang.zh_tw,
            mode: mode ? mode : 'single',
            altInput: true,
            altFormat: 'Y年m月d日',
            dateFormat: 'd-m-Y',
            minDate: minDate ? minDate[0] : null,
            plugins: monthPickr ? [MonthSelectPlugin] : [],
          }}
          value={value || undefined}
          onChange={date => {
            const params = new URLSearchParams(location.search);
            if (onChange) {
              onChange(date);
            } else if (mode === 'range') {
              if (date && date.length === 2) {
                params.set(name, moment(date[0]).toISOString());
                params.set(name2!, moment(date[1]).toISOString());
              } else {
                params.delete(name);
                params.delete(name2!);
              }
              params.set('page', '1');
              history.push({ search: params.toString() });
            } else {
              if (date && date[0]) {
                params.set(name, moment(date[0]).toISOString());
              } else {
                params.delete(name);
              }
              params.set('page', '1');
              history.push({ search: params.toString() });
            }
          }}
        />
        {isClearable ? (
          <i
            className="bx bx-x-circle font-size-16 date-picker-clear-icon"
            onClick={() => {
              const params = new URLSearchParams(location.search);
              params.delete(name);
              if (name2) {
                params.delete(name2);
              }
              // console.log(params.toString());
              history.push({ search: params.toString() });
            }}
          />
        ) : null}
      </InputGroup>
    </div>
  );
};

export default DatePickerFilter;
