/**
 *
 * PopupBannerForm
 *
 */
import Breadcrumbs from 'app/components/Common/Breadcrumb2';
import {
  AutoCompleteField,
  DateField,
  ImageField,
  InputField,
  MultipleSelectField,
  SelectField,
} from 'app/components/Form';
import { Dialog } from 'app/components/Modal';
import { APP_NAME } from 'app/config';
import { BANNER_TYPE_OPTIONS } from 'app/constants';
import { getImageURL } from 'app/helpers/CommonHelper';
import { useAutoComplete } from 'app/hooks';
import {
  CompanyActivityData,
  Event,
  NewsData,
  OptionItems,
  RankCodeOptions,
  SurveyData,
} from 'app/models';
import { CourseData } from 'app/models/Course';
import {
  CreateOneBannerParams,
  createOneBanner,
  deleteOneBanner,
  getBannerList,
  updateOneBanner,
} from 'app/services/BannerServices';
import { uploadImage } from 'app/services/CommonService';
import {
  GetCompanyActivityListParams,
  getCompanyActivityList,
  getOneCompanyActivity,
} from 'app/services/CompanyActivityServices';
import {
  GetCourseListParams,
  getCourseList,
  getOneCourse,
} from 'app/services/CourseServices';
import {
  GetEventListParams,
  getEventList,
  getOneEvent,
} from 'app/services/EventService';
import {
  GetNewsListParams,
  getNewsList,
  getOneNews,
} from 'app/services/NewsServices';
import {
  GetSurveyListParams,
  getOneSurvey,
  getSurveyList,
} from 'app/services/SurveyServices';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { RootState } from 'store';
import { changePreloader } from 'store/Layout';
import * as Yup from 'yup';

// form schema
const Schema = Yup.object().shape({
  bannerType: Yup.string().required('必填項目'),
  eventId: Yup.string()
    .nullable()
    .when('bannerType', (bannerType, passSchema) =>
      bannerType === 'event' ? passSchema.required('必填項目') : passSchema,
    ),
  courseId: Yup.string()
    .nullable()
    .when('bannerType', (bannerType, passSchema) =>
      bannerType === 'course' ? passSchema.required('必填項目') : passSchema,
    ),
  companyActivityId: Yup.string()
    .nullable()
    .when('bannerType', (bannerType, passSchema) =>
      bannerType === 'companyActivity'
        ? passSchema.required('必填項目')
        : passSchema,
    ),
  newsId: Yup.string()
    .nullable()
    .when('bannerType', (bannerType, passSchema) =>
      bannerType === 'news' ? passSchema.required('必填項目') : passSchema,
    ),
  surveyId: Yup.string()
    .nullable()
    .when('bannerType', (bannerType, passSchema) =>
      bannerType === 'survey' ? passSchema.required('必填項目') : passSchema,
    ),
  title: Yup.string().required('必填項目'),
  publishedDate: Yup.date()
    .nullable()
    .when('isPublishNow', (isPublishNow, passSchema) =>
      isPublishNow === 'true' ? passSchema : passSchema.required('必填項目'),
    ),
  verticalImage: Yup.mixed().required('必填項目'),
  permissionRankCodes: Yup.array().nullable(),
});

// form type
type FormItem = {
  title: string;
  verticalImage: any;
  publishedDate: string[] | string;
  isPublishNow: string;
  eventId?: string | null;
  companyActivityId?: string | null;
  courseId?: string | null;
  newsId?: string | null;
  bannerType: string;
  surveyId?: string | null;
  permissionRankCodes: OptionItems[];
};

// form initialValues
const FORM_ITEM = {
  title: '',
  verticalImage: null,
  publishedDate: '',
  isPublishNow: 'true',
  eventId: null,
  companyActivityId: null,
  courseId: null,
  newsId: null,
  surveyId: null,
  bannerType: '',
  permissionRankCodes: [],
};

export const PopupBannerFormPage = () => {
  const dispatch = useDispatch();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );
  const [bannerId, setBannerId] = useState<number | null>(null);
  const [form, setForm] = useState<FormItem>(FORM_ITEM);

  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);

  const initForm = useCallback(async () => {
    dispatch(changePreloader(true));
    try {
      const res = await getBannerList({
        limit: 5,
        offset: 0,
        hasVerticalImage: true,
      });
      const { rows, count } = res;
      if (count > 0) {
        const {
          title,
          verticalImage,
          publishedDate,
          bannerId,
          eventId,
          companyActivityId,
          courseId,
          newsId,
          surveyId,
          permissionRankCodes,
        } = rows[0];
        const bannerType = eventId
          ? 'event'
          : courseId
          ? 'course'
          : companyActivityId
          ? 'companyActivity'
          : newsId
          ? 'news'
          : surveyId
          ? 'survey'
          : '未設定任何種類';
        setBannerId(bannerId);
        setForm({
          title,
          isPublishNow: 'false',
          publishedDate: publishedDate
            ? [moment(publishedDate).toISOString()]
            : '',
          verticalImage: [
            {
              preview: getImageURL(verticalImage, 'original'),
              image: verticalImage,
              name: '現有圖片',
            },
          ],
          eventId: eventId ? eventId.toString() : null,
          courseId: courseId ? courseId.toString() : null,
          surveyId: surveyId ? surveyId.toString() : null,
          companyActivityId: companyActivityId
            ? companyActivityId.toString()
            : null,
          newsId: newsId ? newsId.toString() : null,
          bannerType: bannerType,
          permissionRankCodes:
            permissionRankCodes.length > 0
              ? permissionRankCodes.map(code => {
                  const params: OptionItems = {
                    label: RankCodeOptions[code],
                    value: code,
                  };
                  return params;
                })
              : [],
        });
      } else {
        setBannerId(null);
        setForm({
          title: '',
          verticalImage: null,
          publishedDate: '',
          isPublishNow: 'true',
          bannerType: '',
          permissionRankCodes: [],
        });
      }
    } catch (err) {
      dispatch(changePreloader(false));
      console.log(err);
      toast.warning('請檢查你的網絡。');
    } finally {
      dispatch(changePreloader(false));
    }
  }, [dispatch]);

  useEffect(() => {
    initForm();
  }, [initForm]);

  // form submit
  const onSubmit = async (values: FormItem) => {
    try {
      setLoading(true);
      const {
        title,
        isPublishNow,
        publishedDate,
        verticalImage,
        eventId,
        companyActivityId,
        courseId,
        newsId,
        surveyId,
        permissionRankCodes,
      } = values;
      const params: CreateOneBannerParams = {
        title,
        publishedDate: '',
        verticalImage: '',
        images: [],
        eventId,
        companyActivityId,
        courseId,
        newsId,
        surveyId,
        permissionRankCodes:
          permissionRankCodes.length > 0
            ? permissionRankCodes.map(item => item.value)
            : [],
      };
      if (isPublishNow === 'true') {
        params.publishedDate = moment().toISOString();
      } else {
        params.publishedDate = moment(publishedDate[0]).toISOString();
      }
      if (verticalImage && verticalImage[0].size) {
        const formData = new FormData();
        formData.append(`file`, verticalImage[0]);
        const imagesRes = await uploadImage(formData);
        params.verticalImage = imagesRes.fileName;
        params.images.push(imagesRes.fileName);
      } else {
        params.verticalImage = verticalImage[0].image;
        params.images.push(verticalImage[0].image);
      }
      if (bannerId) {
        await updateOneBanner(bannerId, params);
      } else {
        await createOneBanner(params);
      }
      toast.success('編輯彈出海報成功');
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.success('編輯彈出海報失敗，請重試。');
    } finally {
      setLoading(false);
      initForm();
    }
  };

  // userAutoComplete
  // Event
  const {
    searchText: eventsText,
    setSearchText: setEventText,
    dataList: eventList,
    onMenuScrollToBottom: onEventMenuScrollToBottom,
    updateDataListWithItemCall: updateEventListWithItemCall,
  } = useAutoComplete<GetEventListParams, Event>({
    getItemCall: getOneEvent,
    getListCall: getEventList,
    labelRender: (item: Event) => ({
      label: `#${item.eventId} | ${item.eventTitle}`,
      value: item.eventId.toString(),
    }),
    keyExtracter: (item: Event) => item.eventId,
    searchTextFieldName: 'eventTitle',
  });

  // Course
  const {
    searchText: coursesText,
    setSearchText: setCourseText,
    dataList: courseList,
    onMenuScrollToBottom: onCourseMenuScrollToBottom,
    updateDataListWithItemCall: updateCourseListWithItemCall,
  } = useAutoComplete<GetCourseListParams, CourseData>({
    getItemCall: getOneCourse,
    getListCall: getCourseList,
    labelRender: (item: CourseData) => ({
      label: `#${item.courseId} | ${item.courseTitle} | ${item.courseCode}`,
      value: item.courseId.toString(),
    }),
    keyExtracter: (item: CourseData) => item.courseId,
    searchTextFieldName: 'courseName',
  });

  // Company Activity
  const {
    searchText: companyActivityText,
    setSearchText: setCompanyActivityText,
    dataList: companyActivityList,
    onMenuScrollToBottom: onCompanyActivityMenuScrollToBottom,
    updateDataListWithItemCall: updateCompanyActivityListWithItemCall,
  } = useAutoComplete<GetCompanyActivityListParams, CompanyActivityData>({
    getItemCall: getOneCompanyActivity,
    getListCall: getCompanyActivityList,
    labelRender: (item: CompanyActivityData) => ({
      label: `#${item.companyActivityId} | ${item.title}`,
      value: item.companyActivityId.toString(),
    }),
    keyExtracter: (item: CompanyActivityData) => item.companyActivityId,
    searchTextFieldName: 'title',
  });

  // News
  const {
    searchText: newsText,
    setSearchText: setNewsText,
    dataList: newsList,
    onMenuScrollToBottom: onNewsMenuScrollToBottom,
    updateDataListWithItemCall: updateNewsListWithItemCall,
  } = useAutoComplete<GetNewsListParams, NewsData>({
    getItemCall: getOneNews,
    getListCall: getNewsList,
    labelRender: (item: NewsData) => ({
      label: `#${item.newsId} | ${item.title}`,
      value: item.newsId.toString(),
    }),
    keyExtracter: (item: NewsData) => item.newsId,
    searchTextFieldName: 'title',
  });

  // survey
  const {
    searchText: surveyText,
    setSearchText: setServeyText,
    dataList: surveyList,
    onMenuScrollToBottom: onSurveyMenuScrollToBottom,
    updateDataListWithItemCall: updateSurveyListWithItemCall,
  } = useAutoComplete<GetSurveyListParams, SurveyData>({
    getItemCall: getOneSurvey,
    getListCall: getSurveyList,
    labelRender: (item: SurveyData) => ({
      label: `${item.title}`,
      value: item.surveyId.toString(),
    }),
    keyExtracter: (item: SurveyData) => item.surveyId,
    searchTextFieldName: 'title',
  });

  // Auto Select Field onClick
  const eventOnChange = async (e, setFieldValue) => {
    const eventRes: Event = await updateEventListWithItemCall(e);
    setFieldValue('eventId', `${eventRes.eventId}`);
    setFieldValue('companyActivityId', null);
    setFieldValue('newsId', null);
    setFieldValue('courseId', null);
    setFieldValue('surveyId', null);
    setFieldValue('title', eventRes.eventTitle);
  };

  const courseOnChange = async (e, setFieldValue) => {
    const courseRes: CourseData = await updateCourseListWithItemCall(e);
    setFieldValue('courseId', `${courseRes.courseId}`);
    setFieldValue('companyActivityId', null);
    setFieldValue('newsId', null);
    setFieldValue('eventId', null);
    setFieldValue('surveyId', null);
    setFieldValue('title', courseRes.courseTitle);
  };

  const companyActivityOnChange = async (e, setFieldValue) => {
    const companyActivityRes: CompanyActivityData =
      await updateCompanyActivityListWithItemCall(e);
    setFieldValue(
      'companyActivityId',
      `${companyActivityRes.companyActivityId}`,
    );
    setFieldValue('eventId', null);
    setFieldValue('newsId', null);
    setFieldValue('courseId', null);
    setFieldValue('surveyId', null);
    setFieldValue('title', companyActivityRes.title);
  };

  const newsOnChange = async (e, setFieldValue) => {
    const newsRes: NewsData = await updateNewsListWithItemCall(e);

    setFieldValue('newsId', `${newsRes.newsId}`);
    setFieldValue('companyActivityId', null);
    setFieldValue('eventId', null);
    setFieldValue('courseId', null);
    setFieldValue('surveyId', null);
    setFieldValue('title', newsRes.title);
  };

  const surveyOnChange = async (e, setFieldValue) => {
    const surveyRes: SurveyData = await updateSurveyListWithItemCall(e);
    let imageRes: any;
    if (Array.isArray(surveyRes.images) && surveyRes.images.length > 0) {
      imageRes = [
        {
          preview: getImageURL(surveyRes.images[0], 'original'),
          image: surveyRes.images[0],
          name: '現有圖片',
        },
      ];
    }
    const index = _.findIndex(surveyList, item => item.value === e);
    if (index > -1) {
      setFieldValue('title', surveyList[index].label);
    }

    setFieldValue('surveyId', `${surveyRes.surveyId}`);
    setFieldValue('title', surveyRes.title);
    setFieldValue('newsId', null);
    setFieldValue('companyActivityId', null);
    setFieldValue('eventId', null);
    setFieldValue('courseId', null);
    setFieldValue('images', imageRes);
  };

  const deletePopupBanner = async () => {
    try {
      if (bannerId) {
        setDeleteLoading(true);
        await deleteOneBanner(bannerId);
        toast.success('刪除成功');
        setBannerId(null);
        setDeleteVisible(false);
        initForm();
      }
    } catch (err) {
      console.log(err);
      toast.error('刪除失敗，請重試　');
    } finally {
      setDeleteLoading(false);
    }
  };

  const TITLE = `編輯彈出海報`;

  const BreadcrumbsOptions = [{ title: TITLE, path: '#' }];
  return (
    <>
      <div className="page-content">
        <Helmet>
          <title>
            {TITLE} | {APP_NAME}
          </title>
        </Helmet>
        <Container fluid={true}>
          <Breadcrumbs title="彈出海報" breadcrumbItems={BreadcrumbsOptions} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle>彈出海報資料</CardTitle>
                  {!isPreloader ? (
                    <div className="p-2">
                      <Formik
                        initialValues={form}
                        validationSchema={Schema}
                        onSubmit={onSubmit}
                        enableReinitialize
                      >
                        {({ values, setFieldValue }) => (
                          <Form className="form-horizontal">
                            <Row>
                              <Col lg={6}>
                                <InputField
                                  name="title"
                                  label="海報名稱"
                                  placeholder="海報名稱"
                                  type="text"
                                />
                              </Col>
                            </Row>

                            <Row>
                              <Col md={6}>
                                <SelectField
                                  name={'bannerType'}
                                  label={'橫額類型'}
                                  placeholder={'橫額類型'}
                                  type={'text'}
                                  options={BANNER_TYPE_OPTIONS}
                                  isClearable
                                  onChange={e => {
                                    console.log(e);
                                    setFieldValue('eventId', null);
                                    setFieldValue('courseId', null);
                                    setFieldValue('companyActivityId', null);
                                    setFieldValue('newsId', null);
                                  }}
                                />
                              </Col>
                              {values.bannerType === 'event' ? (
                                <Col sm={6}>
                                  <AutoCompleteField
                                    name="eventId"
                                    label="活動"
                                    placeholder="活動"
                                    inputValue={eventsText}
                                    onInputChange={setEventText}
                                    options={eventList}
                                    onMenuScrollToBottom={
                                      onEventMenuScrollToBottom
                                    }
                                    onChange={e =>
                                      eventOnChange(e, setFieldValue)
                                    }
                                  />
                                </Col>
                              ) : null}
                              {values.bannerType === 'course' ? (
                                <Col sm={6}>
                                  <AutoCompleteField
                                    name="courseId"
                                    label="課程"
                                    placeholder="課程"
                                    inputValue={coursesText}
                                    onInputChange={setCourseText}
                                    options={courseList}
                                    onMenuScrollToBottom={
                                      onCourseMenuScrollToBottom
                                    }
                                    onChange={e =>
                                      courseOnChange(e, setFieldValue)
                                    }
                                  />
                                </Col>
                              ) : null}
                              {values.bannerType === 'companyActivity' ? (
                                <Col sm={6}>
                                  <AutoCompleteField
                                    name="companyActivityId"
                                    label="公司活動"
                                    placeholder="公司活動"
                                    inputValue={companyActivityText}
                                    onInputChange={setCompanyActivityText}
                                    options={companyActivityList}
                                    onMenuScrollToBottom={
                                      onCompanyActivityMenuScrollToBottom
                                    }
                                    onChange={e =>
                                      companyActivityOnChange(e, setFieldValue)
                                    }
                                  />
                                </Col>
                              ) : null}
                              {values.bannerType === 'news' ? (
                                <Col sm={6}>
                                  <AutoCompleteField
                                    name="newsId"
                                    label="新聞"
                                    placeholder="新聞"
                                    inputValue={newsText}
                                    onInputChange={setNewsText}
                                    options={newsList}
                                    onMenuScrollToBottom={
                                      onNewsMenuScrollToBottom
                                    }
                                    onChange={e =>
                                      newsOnChange(e, setFieldValue)
                                    }
                                  />
                                </Col>
                              ) : null}
                              {values.bannerType === 'survey' ? (
                                <Col sm={6}>
                                  <AutoCompleteField
                                    name="surveyId"
                                    label="資源及評價"
                                    placeholder="資源及評價"
                                    inputValue={surveyText}
                                    onInputChange={setServeyText}
                                    options={surveyList}
                                    onMenuScrollToBottom={
                                      onSurveyMenuScrollToBottom
                                    }
                                    onChange={e =>
                                      surveyOnChange(e, setFieldValue)
                                    }
                                  />
                                </Col>
                              ) : null}
                            </Row>

                            <Row>
                              <Col sm={12} className="mb-3">
                                <MultipleSelectField
                                  name="permissionRankCodes"
                                  placeholder="請選擇"
                                  label="職位權限"
                                  options={Object.keys(RankCodeOptions).map(
                                    key => ({
                                      label: RankCodeOptions[key],
                                      value: key,
                                    }),
                                  )}
                                />
                              </Col>
                            </Row>

                            <Row>
                              <Col md={6}>
                                <SelectField
                                  name="isPublishNow"
                                  label="發佈時間"
                                  options={[
                                    { label: '立即發佈', value: 'true' },
                                    { label: '預約發佈', value: 'false' },
                                  ]}
                                />
                              </Col>
                              {values.isPublishNow !== 'true' ? (
                                <Col md={6}>
                                  <DateField
                                    name="publishedDate"
                                    label="發佈時間"
                                    placeholder="發佈時間"
                                    enableTimeInCAL
                                  />
                                </Col>
                              ) : null}
                            </Row>
                            <Row>
                              <Col md={12}>
                                <ImageField
                                  name="verticalImage"
                                  label="海報 (請上傳寬高比率為9:16的圖片)"
                                />
                              </Col>
                            </Row>

                            <div className="mt-3 d-flex flex-row-reverse">
                              <Button
                                type="submit"
                                color="primary"
                                className="ms-1"
                                disabled={loading}
                              >
                                {loading ? (
                                  <i className="bx bx-loader-circle bx-spin" />
                                ) : bannerId ? (
                                  '更改'
                                ) : (
                                  '提交'
                                )}
                              </Button>
                              {bannerId ? (
                                <Button
                                  type="button"
                                  color="danger"
                                  className="me-1"
                                  onClick={() => setDeleteVisible?.(true)}
                                >
                                  {deleteLoading ? (
                                    <i className="bx bx-loader-circle bx-spin" />
                                  ) : (
                                    '刪除'
                                  )}
                                </Button>
                              ) : null}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Dialog
        visible={deleteVisible!}
        title={'刪除彈出海報'}
        onClose={() => setDeleteVisible?.(false)}
        loading={deleteLoading}
        onConfirm={() => deletePopupBanner()}
      >
        <p>{`確定要刪除此彈出海報嗎?`}</p>
      </Dialog>
    </>
  );
};
