import { Filter, ListResult } from 'app/models';
import {
  SurveyData,
  SurveyQuestions,
  SurveyResponseListItem,
} from 'app/models/Survey';

import Axios from './';

/**
* * get all survey (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/surveys)
+*/

export interface GetSurveyListParams extends Filter<SurveyData> {
  title?: string;
}

export const getSurveyList = async (params: GetSurveyListParams) => {
  const { data } = await Axios.get<ListResult<SurveyData>>('/surveys', {
    params,
  });
  return data;
};

/**
* * get one survey (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/surveys/-surveyId-)
+*/

export const getOneSurvey = async (surveyId: number | string) => {
  const { data } = await Axios.get<SurveyData>(`/surveys/${surveyId}`);
  return data;
};

/**
* * create one survey (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/surveys)
+*/

export interface CreateOneSurveyParams {
  isActive: boolean;
  title: string;
  successContent: string;
  successTitle: string;
  successSubtitle: string;
  description: string;
  images: any;
  questions: SurveyQuestions[];
  isMemberOnly: boolean;
  maxSubmissionPerPerson: number;
  permissionTags: string[];
  mailingList: string[] | null;
}

export const createOneSurvey = async (params: CreateOneSurveyParams) => {
  const { data } = await Axios.post<SurveyData>('/surveys', params);
  return data;
};

/**
* * update one survey (https://svgroup-app-dev.appicidea.com/doc/v2#patch-/v1/surveys/-surveyId-)
+*/

export interface UpdateOneSurveyParams extends Partial<CreateOneSurveyParams> {}

export const updateOneSurvey = async (
  surveyId: number | string,
  params: UpdateOneSurveyParams,
) => {
  const { data } = await Axios.patch<SurveyData>(
    `/surveys/${surveyId}`,
    params,
  );
  return data;
};

/**
* * delete one survey (https://svgroup-app-dev.appicidea.com/doc/v2#delete-/v1/surveys/-surveyId-)
+*/

export const deleteOneSurvey = async (surveyId: number | string) => {
  const { data } = await Axios.delete<string>(`/surveys/${surveyId}`);
  return data;
};

/**
* * gte one survey res (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/surveys/responses)
+*/

export interface GetSurveyResponseListParams
  extends Filter<SurveyResponseListItem> {
  courseId?: number | string;
  courseScheduleId?: number | string;
  eventId?: number | string;
  surveyId?: number | string;
  userId?: number | string;
}

export const getSurveyResponseListData = async (
  params: GetSurveyResponseListParams,
) => {
  const { data } = await Axios.get<ListResult<SurveyResponseListItem>>(
    '/surveys/responses',
    { params },
  );
  return data;
};
