import { PAGE_SIZE } from 'app/config';
import { Filter, ListResult } from 'app/models';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

export interface UseTableDataParams<P extends Filter<R>, R> {
  call: (params: P) => Promise<ListResult<R>>;
  filterVariableArray: (keyof P)[];
  limit?: number;
  initialParams?: P;
}

export const useTableData = <P extends Filter<R>, R>({
  call,
  filterVariableArray,
  limit,
  initialParams,
}: UseTableDataParams<P, R>) => {
  const location = useLocation();
  const [dataList, setDataList] = useState<R[]>([]);
  const [filter, setFilter] = useState<P>(initialParams as P);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const isFirstLoad = useRef(true);

  const getDataList = useCallback(async () => {
    if (!isFirstLoad.current) {
      try {
        const { count: tempCount, rows } = await call(filter);
        setDataList(rows);
        setCount(tempCount);
      } catch (err: any) {
        toast.error('請檢查你的網絡。');
        console.error(err);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call, filter]);

  useEffect(() => {
    getDataList();
  }, [getDataList]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const p = params.get('page');
    let tempFilter;
    if (filter) {
      tempFilter = { ...filter };
    } else {
      tempFilter = { limit: limit ? limit : PAGE_SIZE, offset: 0 };
    }
    if (filterVariableArray) {
      filterVariableArray?.forEach(key => {
        const value = params.get(key.toString());
        tempFilter[key] =
          filter && isFirstLoad.current && filter[key]
            ? filter[key]
            : value
            ? value
            : undefined;
      });
    }
    if (p) {
      tempFilter.offset = (parseInt(p) - 1) * (limit ? limit : PAGE_SIZE);
      setPage(parseInt(p));
    }
    setFilter(tempFilter);
    isFirstLoad.current = false;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return {
    filter,
    setFilter,
    count,
    dataList,
    setDataList,
    page,
    setPage,
    getDataList,
  } as const;
};
