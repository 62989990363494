import { OptionItems } from 'app/models';
import { FieldHookConfig, useField } from 'formik';
import _ from 'lodash';
import React, { RefObject, useEffect, useState } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
} from 'reactstrap';

import './autoCompleteField.scss';

interface OtherProps {
  label: string;
  horizontal?: boolean;
  placeholder?: string;
  disabled?: boolean;
  options: OptionItems[];
  inputValue: string;
  onInputChange: (e) => void;
  onMenuScrollToBottom?: () => void;
  onChange?: (value: string | number) => void;
  noOptionText?: string;
  height?: number;
}

const AutoCompleteField = (
  props: OtherProps & FieldHookConfig<string | number | null>,
) => {
  const [field, meta, helpers] = useField(props);
  const [menu, setMenu] = useState(false);
  const [inputText, setInputText] = useState<string>('');
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [cacheOptions, setCacheOption] = useState<OptionItems[]>([]);

  const {
    label,
    placeholder,
    disabled,
    options,
    onChange,
    onInputChange,
    onMenuScrollToBottom,
    noOptionText,
    height = 318,
  } = props;

  useEffect(() => {
    const tempList = [...cacheOptions, ...options];
    const unique = Array.from(
      new Map(tempList.map(obj => [JSON.stringify(obj), obj])).values(),
    );
    if (unique.length !== cacheOptions.length) {
      setCacheOption(unique);
    }
  }, [options, cacheOptions]);

  let selectedValue: string = '';
  const index = _.findIndex(
    cacheOptions,
    option => option.value === field.value,
  );
  if (index > -1) {
    selectedValue = cacheOptions[index].label;
  }

  const filteredOptions = cacheOptions.filter(option =>
    option.label.toLowerCase().includes(inputText.toLowerCase()),
  );

  const bottomReached = () => {
    onMenuScrollToBottom && onMenuScrollToBottom();
  };

  const scrollRef: RefObject<HTMLDivElement> =
    useBottomScrollListener(bottomReached);

  const inputFieldCrossBtnOnClick = e => {
    if (e) {
      e.stopPropagation();
      helpers.setValue(null);
    }
  };

  return (
    <Dropdown
      className={`auto-complete form-group mb-2 ${
        meta.touched && meta.error && 'text-danger'
      }`}
      toggle={() => {
        if (isFocus) {
          setMenu(true);
        } else {
          setMenu(!menu);
        }
      }}
      style={{ height: label ? 85 : 60 }}
      isOpen={menu}
      disabled={disabled}
    >
      {label ? <Label>{props.label}</Label> : null}
      <DropdownToggle tag="div">
        {/* input field */}
        <Input
          className="form-control"
          placeholder={placeholder}
          type="text"
          autoComplete="new-password"
          disabled={disabled}
          value={isFocus ? inputText : selectedValue}
          onChange={e => {
            setInputText(e.target.value);
            setIsFocus(true);
            if (onInputChange) onInputChange(e.target.value);
          }}
          onFocus={() => setIsFocus(true)}
          onBlur={() => {
            setIsFocus(false);
          }}
        />
        {selectedValue && !disabled && (
          <div
            className="position-absolute"
            style={{
              bottom: '27px',
              right: '10px',
              cursor: 'pointer',
              zIndex: 999,
            }}
            onClick={e => inputFieldCrossBtnOnClick(e)}
          >
            <i className="bx bx-x-circle fs-5"></i>
          </div>
        )}
      </DropdownToggle>

      {/* drop down */}
      <DropdownMenu className="dropdown-menu-start w-100">
        <div
          className="drop-down-card drop-down-menu"
          ref={scrollRef}
          style={{ height: `${height}px` }}
        >
          <div className="drop-down-card-body">
            {filteredOptions.map(option => (
              <div
                className={`drop-down-option-item ${
                  field.value === option.value ? 'selected' : ''
                }`}
                key={option.value}
                onClick={() => {
                  if (onChange) {
                    onChange(option.value);
                  } else {
                    helpers.setValue(option.value);
                  }
                  setMenu(false);
                  setInputText('');
                }}
              >
                {option.label}
              </div>
            ))}
            {filteredOptions?.length === 0 && (
              <div className="no-option-text">
                {noOptionText || '沒有選項'}{' '}
              </div>
            )}
          </div>
        </div>
      </DropdownMenu>

      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </Dropdown>
  );
};

export default AutoCompleteField;
