import { AdminFormPage, AdminListPage } from 'app/pages/AdminPage';
import { LoginPage, LogoutPage } from 'app/pages/Authentication';
import {
  BannerFormPage,
  BannerListPage,
  BannerSortPage,
} from 'app/pages/BannerPage';
import { PopupBannerFormPage } from 'app/pages/BannerPage/PopupBannerFormPage';
import {
  CompanyActivityFormPage,
  CompanyActivityListPage,
} from 'app/pages/CompanyActivityPage';
import {
  ContactDetailsPage,
  ContactFormPage,
  ContactListPage,
  ContactSortPage,
} from 'app/pages/ContactPage';
import {
  CourseDetailsPage,
  CourseFormPage,
  CourseListPage,
} from 'app/pages/CoursePage';
import { DrivePage } from 'app/pages/DrivePage/loadable';
import {
  EventDetailsPage,
  EventFormPage,
  EventListPage,
} from 'app/pages/EventPage';
import { HomePage } from 'app/pages/HomePage/Loadable';
import { InfoFormPage, InfoListPage, InfoSortPage } from 'app/pages/InfoPage';
import { MaintenanceFormPage } from 'app/pages/MaintenancePage';
import { NewsFormPage, NewsListPage, NewsSortPage } from 'app/pages/NewsPage';
import { NotFoundPage } from 'app/pages/NotFoundPage/Loadable';
import {
  NotificationFormPage,
  NotificationListPage,
} from 'app/pages/NotificationPage';
import {
  PerformanceFormPage,
  PerformanceListPage,
  RankingListPage,
} from 'app/pages/PerformancePage';
import { PrivacyPolicyPage } from 'app/pages/PrivacyPolicyPage';
import {
  StatementDetailsPage,
  StatementFormPage,
  StatementListPage,
} from 'app/pages/StatementPage';
import {
  SurveyFormPage,
  SurveyListPage,
  SurveyResponseDetailsPage,
  SurveyResponseListPage,
} from 'app/pages/SurveyPage';
import { TransactionListPage } from 'app/pages/TransactionPage';
import {
  UserDetailsPage,
  UserFormPage,
  UserListPage,
} from 'app/pages/UserPage';
import { UserTagFormPage } from 'app/pages/UserPage/UserTagFormPage/Loadable';
import { UserTagListPage } from 'app/pages/UserPage/UserTagListPage';
import { VideoPlayerPage } from 'app/pages/VideoPlayerPage/loadable';
import { Redirect } from 'react-router-dom';

interface Route {
  path: string;
  component?: any;
  exact?: boolean;
  redirect?: string;
}
const userRoutes: Route[] = [
  { path: '/', component: HomePage },

  // admin
  { path: '/admins', component: AdminListPage },
  { path: '/admins/new', component: AdminFormPage, exact: true },
  { path: '/admins/edit/:adminId', component: AdminFormPage, exact: true },
  {
    path: '/admins/edit/:adminId/:password',
    component: AdminFormPage,
    exact: true,
  },

  // user
  { path: '/users', component: UserListPage, exact: true },
  { path: '/users/new', component: UserFormPage },
  { path: '/users/view/:userId', component: UserDetailsPage },
  { path: '/users/edit/:userId', component: UserFormPage },

  // user tags
  { path: '/user-tags', component: UserTagListPage },
  { path: '/user-tags/new', component: UserTagFormPage },
  { path: '/user-tags/edit/:tagName', component: UserTagFormPage },

  // event
  { path: '/events', component: EventListPage, exact: true },
  { path: '/events/new', component: EventFormPage },
  { path: '/events/view/:eventId', component: EventDetailsPage },
  { path: '/events/edit/:eventId', component: EventFormPage },

  // course
  { path: '/courses', component: CourseListPage, exact: true },
  { path: '/courses/new', component: CourseFormPage },
  { path: '/courses/view/:courseId', component: CourseDetailsPage },
  { path: '/courses/edit/:courseId', component: CourseFormPage },

  //news
  { path: '/news', component: NewsListPage, exact: true },
  { path: '/news/new', component: NewsFormPage },
  // { path: '/news/view/:newsId', component: CourseDetailsPage },
  { path: '/news/edit/:newsId', component: NewsFormPage },
  { path: '/news-sort', component: NewsSortPage },

  //info
  { path: '/infos', component: InfoListPage },
  { path: '/infos/new', component: InfoFormPage },
  // { path: '/infos/view/:infoId', component: CourseDetailsPage },
  { path: '/infos/edit/:infoId', component: InfoFormPage },
  { path: '/infos-sort', component: InfoSortPage },

  // notifications
  { path: '/notifications', component: NotificationListPage },
  { path: '/notifications/new', component: NotificationFormPage },
  {
    path: '/notifications/copy/:notificationId',
    component: NotificationFormPage,
  },

  // Contacts Category page
  { path: '/contacts', component: ContactListPage },
  { path: '/contacts/new', component: ContactFormPage },
  { path: '/contacts/view/:categoryId', component: ContactDetailsPage },
  {
    path: '/contacts/edit/:categoryId',
    component: ContactFormPage,
  },
  // Contacts page
  { path: '/contacts/contacts-sort/:categoryId', component: ContactSortPage },
  { path: '/contact-category-sort', component: ContactSortPage },

  // Company Activity
  { path: '/company', component: CompanyActivityListPage },
  { path: '/company/new', component: CompanyActivityFormPage },
  {
    path: '/company/edit/:companyActivityId',
    component: CompanyActivityFormPage,
  },

  // Statements
  { path: '/statements', component: StatementListPage },
  { path: '/statements/new', component: StatementFormPage },
  { path: '/statements/edit/:statementId', component: StatementFormPage },
  { path: '/statements/view/:statementId', component: StatementDetailsPage },

  // Transaction
  { path: '/transactions', component: TransactionListPage },

  //Banner
  { path: '/banners', component: BannerListPage },
  { path: '/banners/new', component: BannerFormPage },
  { path: '/banners/edit/:bannerId', component: BannerFormPage },
  { path: '/banners-sort', component: BannerSortPage },
  { path: '/popup-banner', component: PopupBannerFormPage },

  // Performance
  { path: '/performances', component: PerformanceListPage },
  { path: '/performances/new', component: PerformanceFormPage },
  { path: '/performances/edit/:performanceId', component: PerformanceFormPage },
  { path: '/ranking', component: RankingListPage },

  // Survey
  { path: '/surveys', component: SurveyListPage },
  { path: '/surveys/new', component: SurveyFormPage },
  { path: '/surveys/edit/:surveyId', component: SurveyFormPage },
  { path: '/surveys-response', component: SurveyResponseListPage },
  {
    path: '/surveys-response/view/:surveyResponseId',
    component: SurveyResponseDetailsPage,
  },

  //Maint
  { path: '/maintenances', component: MaintenanceFormPage },

  // drive page
  { path: '/drive', component: DrivePage },

  { path: '/notFound', component: NotFoundPage },
  { path: '*', component: () => <Redirect to="/notFound" /> },
];

const authRoutes: Route[] = [
  { path: '/logout', component: LogoutPage },
  { path: '/login', component: LoginPage },
  { path: '/privacy-policy', component: PrivacyPolicyPage },
  { path: '/watch', component: VideoPlayerPage },
  { path: '/notFound', component: NotFoundPage },
];

export { userRoutes, authRoutes };
