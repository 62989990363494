import LOADING from 'app/assets/images/loading.gif';
import { IMAGE_SIZE } from 'app/config';
// import {
//   DisplayFileType,
//   DriveObjectType,
//   DriveType,
//   FileType,
// } from 'app/models';
import { uploadImage } from 'app/services/CommonService';
import { FieldHookConfig, useField } from 'formik';
import { useMemo, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Label } from 'reactstrap';

import { Dialog } from '../Modal';
import LinkForm from './Html/LinkForm';

// font size
const fontSizeArr = [
  '8px',
  '9px',
  '10px',
  '12px',
  '14px',
  '16px',
  '20px',
  '24px',
];

// line height
const lineHeightArr = [1, 1.2, 1.5, 2, 3];

const Parchment = Quill.import('parchment');
const LineStyle = new Parchment.Attributor.Style('lineHeight', 'line-height', {
  scope: Parchment.Scope.INLINE,
  whiteList: lineHeightArr,
});

const Size = Quill.import('attributors/style/size');
const Align = Quill.import('attributors/style/align');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);
Quill.register(Align, true);
Quill.register(LineStyle, true);

// text and bg colors
const COLOR_ARR = [
  '#000000',
  '#222222',
  '#444444',
  '#666666',
  '#888888',
  '#cccccc',
  '#ffffff',

  '#e74c3c',
  '#f39c12',
  '#fdda00',
  '#61a951',
  '#07a9fe',
  '#8e44ad',
  '#f32784',

  '#c0392b',
  '#d35400',
  '#f1c40f',
  '#16a085',
  '#003ba5',
  '#6F1E51',
  '#B53471',
];

interface OtherProps {
  label: string;
  placeholder?: string;
}

let icons = Quill.import('ui/icons');
icons['emails'] = '<i class="mdi mdi-email-outline" aria-hidden="true"></i>';
icons['tel'] = '<i class="bx bx-phone" aria-hidden="true"></i>';

export type LinkType = 'link' | 'email' | 'tel' | 'video';

const HTMLField = (props: OtherProps & FieldHookConfig<string>) => {
  const [field, meta, helpers] = useField(props);
  const { label, placeholder } = props;

  const [linkVisibleType, setLinkVisibleType] = useState<LinkType | null>(null);
  const [videoLinkVisible, setVideoLinkVisible] = useState<boolean>(false);
  const quillRef = useRef<ReactQuill | null>(null);

  const imageHandler = () => {
    // get editor
    const editor = quillRef?.current?.getEditor();
    // @ts-ignore
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input?.files?.[0] || '';
      if (file && file.size > IMAGE_SIZE) {
        toast.success('圖片不可大於10MB');
        return;
      }
      const formData = new FormData();
      formData.append('file', file);
      if (editor) {
        const range = editor.getSelection();

        if (range) {
          editor.insertEmbed(range.index, 'image', LOADING);
          editor.setSelection(range);

          try {
            const res = await uploadImage(formData);
            editor.deleteText(range.index, 1);
            editor.insertEmbed(
              range.index,
              'image',
              `${res.url}/original/${res.fileName}`,
            );
          } catch (err) {
            editor.deleteText(range.index, 1);
            toast.warning('上載圖片失敗，請重試。');
          }
        }
      }
    };
  };

  // link button onClick
  const linkHandler = e => {
    // get editor
    const editor = quillRef?.current?.getEditor();
    if (editor) {
      const range = editor.getSelection();
      if (range?.length) {
        setLinkVisibleType('link');
      }
    }
  };

  // Video Handler
  // const videoHandler = () => {
  //   setVideoLinkVisible(true);
  // };

  const emailHandler = () => {
    // console.log('email onClick');
    const editor = quillRef?.current?.getEditor();
    if (editor) {
      const range = editor.getSelection();
      if (range?.length) {
        setLinkVisibleType('email');
      }
    }
  };

  const telHandler = () => {
    const editor = quillRef?.current?.getEditor();
    if (editor) {
      const range = editor.getSelection();
      if (range?.length) {
        setLinkVisibleType('tel');
      }
    }
  };

  const videoLinkOnSubmit = values => {
    // const { links } = values;
    // const editor = quillRef?.current?.getEditor();
    // if (editor) {
    //   const file = {
    //     dir: '',
    //     driveType: 'public' as DriveType,
    //     fileName: '18Tes.mp4',
    //     fileType: 'video' as DisplayFileType,
    //     folderType: 'others' as FileType,
    //     isBookmark: false,
    //     type: 'File' as DriveObjectType,
    //   };
    //   editor.insertEmbed(
    //     0,
    //     'video',
    //     'https://static.appicidea.com/original/S3/XWWIRFILNTQYPCXIRBGU/18Tes.mp4',
    //   );
    // }
  };

  // link form modal onSubmit
  const linkOnSubmit = ({ link }: { link: string }) => {
    // get editor
    const editor = quillRef?.current?.getEditor();
    if (editor) {
      editor.format('link', link);
      setLinkVisibleType(null);
    }
  };

  // quill modules
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ size: fontSizeArr }],
          [{ lineHeight: lineHeightArr }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ align: [] }],
          [{ color: COLOR_ARR }, { background: COLOR_ARR }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link', 'image', 'video', 'emails', 'tel'],
        ],
        handlers: {
          image: imageHandler,
          link: linkHandler,
          // video: videoHandler,
          emails: emailHandler,
          tel: telHandler,
        },
      },
    }),
    [],
  );

  return (
    <>
      {label ? (
        <Label className={meta.touched && meta.error ? 'text-danger' : ''}>
          {props.label}
        </Label>
      ) : null}

      {/* html editor */}
      <div className="html-editor">
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={field.value}
          modules={modules}
          onChange={html => {
            helpers.setValue(html.replaceAll('src="http://', 'src="https://'));
          }}
          placeholder={placeholder}
          scrollingContainer="#scrolling-container"
        />
        {meta.touched && meta.error ? (
          <div className="text-danger">{meta.error}</div>
        ) : null}
      </div>

      {/* link form popup */}
      <Dialog
        visible={linkVisibleType ? true : false}
        size="md"
        title={`輸入${
          linkVisibleType === 'link'
            ? 'Link'
            : linkVisibleType === 'email'
            ? '電郵'
            : '電話'
        }`}
        onClose={() => setLinkVisibleType(null)}
      >
        <LinkForm linkOnSubmit={linkOnSubmit} linkType={linkVisibleType!} />
      </Dialog>
      <Dialog
        visible={videoLinkVisible}
        size="md"
        title="輸入影片網址"
        onClose={() => setVideoLinkVisible(false)}
      >
        <LinkForm
          linkOnSubmit={videoLinkOnSubmit}
          linkType={linkVisibleType!}
        />
      </Dialog>
    </>
  );
};

export default HTMLField;
