import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { RootState } from 'store';

// import { useAdminPermission } from 'store/Permission';

interface Props {
  component: any;
  isAuthProtected: boolean;
  layout?: any;
  exact?: boolean;
  path: string;
  redirect?: string;
}

const ProtectedRoute = ({
  component: Component,
  isAuthProtected,
  layout: Layout,
  exact,
  path,
  redirect,
  ...rest
}: Props) => {
  const { Auth } = useSelector((rootState: RootState) => rootState.auth);
  return (
    <Route
      {...rest}
      path={path}
      exact
      render={props => {
        if (!Auth && isAuthProtected) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }

        // permission check here

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default ProtectedRoute;
