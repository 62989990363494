import { IMAGE_URL } from 'app/config';
import {
  CourseAttendees,
  FormattedCourseAttendeeAndCourseApplicationType,
} from 'app/models/Course';
import { toast } from 'react-toastify';

export const getImageURL = (imgSrc: string, size?: number | 'original') => {
  if (imgSrc && imgSrc.includes('https')) {
    return imgSrc;
  } else if (imgSrc) {
    return `${IMAGE_URL}/${size ? size : '320'}/${imgSrc}`;
  }
  return '';
};

export const formatPrice = (price: number) => {
  if (price) {
    const dollarUS = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return dollarUS.format(price);
  }
  return '-';
};

export const getRegionDistrictObject = () => {
  try {
    const regionDistrict = localStorage.getItem('regionDistrict');
    if (regionDistrict) {
      return JSON.parse(regionDistrict);
    } else {
      throw new Error('regionDistrict is not a string');
    }
  } catch (err) {
    console.error(err);
    toast.warning('無法解析區域 Json。');
    return {};
  }
};

export const findMinDate = CourseSchedules => {
  // Need to fixed:
  // filter the date is not empty + checking the date is valid for the maxDate type of not

  let dateToNumber = CourseSchedules.map(el => {
    if (Array.isArray(el.date)) {
      return Number(el.date[0]);
    } else {
      return Date.parse(el.date);
    }
  });

  let minDate = new Date(Math.min.apply(null, dateToNumber));

  return minDate;
};

export const errorInGetingData = (errMsg: any) => {
  if (errMsg.response.data.errors.length > 1) {
  } else {
    if (errMsg.response.data.message) {
      if (errMsg.response.data.message.includes('file already exists')) {
        toast.error(`新增文件夾失敗 (已有相同文件夾)，請重試`);
      }
    }
  }
};

export const trimmingTime = (time?: string) => {
  if (time) {
    const strArr = time.split(':');
    return `${strArr[0]}:${strArr[1]}`;
  }
  return '-';
};

export const formatTableWithCourseAttendeesData = (
  courseAttendees: CourseAttendees,
): FormattedCourseAttendeeAndCourseApplicationType => {
  const {
    CourseApplication,
    User,
    courseAttendeeId,
    courseApplicationId,
    courseId,
    userId,
    preAgentId,
    PreAgent,
  } = courseAttendees;

  const data: FormattedCourseAttendeeAndCourseApplicationType = {
    // courseApplicationId: null,
    // courseId: null,
    // userId: null,
    paymentId: null,
    status: null,
    totalPaymentAmount: null,
    totalMembershipPointUsed: null,
    paymentMethod: null,
    // willMemberAtten: null,
    // User: null,
    Course: null,
    Attendees: null,
    paymentOption: null,
    paymentStatus: null,

    CourseApplication,

    // preAgentId,
    PreAgent,

    User,
    courseAttendeeId,
    courseApplicationId,
    courseId,
    userId,
    preAgentId,
    // PreAgent,
  };
  return data;
};
