/**
 *
 * Asynchronously loads the component for EventFormPage
 *
 */
import { lazyLoad } from 'utils/loadable';

export const EventFormPage = lazyLoad(
  () => import('./index'),
  module => module.EventFormPage,
);
