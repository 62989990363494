import { UserTagItem } from 'app/models';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

const UserTagListColumns = () => [
  {
    text: 'Tag',
    dataField: 'tagName',
    canSort: true,
    formatter: (item: UserTagItem) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">{item.tagName}</div>
        </h5>
      </>
    ),
  },
  {
    text: '擁有此Tag會員數目',
    dataField: '',
    canSort: true,
    formatter: (item: UserTagItem) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">{`${item.userCount} 人`}</div>
        </h5>
      </>
    ),
  },
  {
    dataField: 'menu',
    isDummyField: true,
    text: '',
    formatter: (item: UserTagItem) => (
      <ul className="list-inline font-size-20 contact-links mb-0 d-flex flex-row justify-content-end">
        <li className="list-inline-item px-2">
          <Link
            to={`/user-tags/edit/${encodeURIComponent(item.tagName)}`}
            data-for={'edit' + item.tagName}
            data-tip
          >
            <i className="bx bx-edit-alt" />
            <ReactTooltip effect="solid" id={'edit' + item.tagName}>
              編輯會員Tag
            </ReactTooltip>
          </Link>
        </li>
      </ul>
    ),
  },
];

export default UserTagListColumns;
