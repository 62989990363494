export const EVENT_STATUS_OPTIONS = [
  {
    label: '未開始',
    value: '未開始',
  },
  {
    label: '已完結',
    value: '已完結',
  },
];

export const EVENT_ALLOWREGISTRATION_OPTIONS = [
  { label: '所有', value: '' },
  { label: '不可報名', value: 'false' },
  { label: '可報名', value: 'true' },
];

export const EVENT_EVENTSTATUS_ALL_OPTIONS = [
  { label: '所有', value: '' },
  { label: '未開始', value: '未開始' },
  { label: '進行中', value: '進行中' },
  { label: '己結束', value: '己結束' },
];

export const EVENT_ISONLINE_OPTIONS = [
  { label: '所有', value: '' },
  { label: '實體活動', value: 'false' },
  { label: '網上活動', value: 'true' },
];

export const EVENT_MEMBER_OPTIONS = [
  { label: '會員限定', value: 'true' },
  { label: '不限會員', value: 'false' },
];

export const EVENT_ALL_MEMBER_OPTIONS = [
  { label: '所有', value: '' },
  { label: '會員限定', value: 'true' },
  { label: '不限會員', value: 'false' },
];

export const EVENT_ISFULL_OPTIONS = [
  { label: '所有', value: '' },
  { label: '仍可報名', value: 'false' },
  { label: '額滿', value: 'true' },
];

export const EVENT_APPLICATION_STATUS_OPTIONS = [
  { label: '所有', value: '' },
  { label: '已報名', value: 'reserved' },
  { label: '等待評價', value: 'pendingReview' },
  { label: '已完成', value: 'completed' },
  { label: '已取消', value: 'canceled' },
];

export const EVENT_CHECKIN_STATUS_OPTIONS = [
  { label: '所有', value: '' },
  { label: '已簽到', value: 'true' },
  { label: '未簽到', value: 'false' },
];
